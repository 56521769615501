import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar";
import { SpocSidebarData } from "../../components/Spoc/Sidebar/SidebarData";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useEffect, useState } from "react";
import requireAuth from "../../utils/requireAuth";
import RouteHelper from "../../utils/routeHelper";
import Loading from "../../components/Loading";
import Problems from "./Problems";
import Tracks from "./Tracks";
import Dashboard from "./Dashboard";
import ProblemSubmission from "./ProblemSubmission";
import { SPOCSubmittedTracks } from "./TrackSubmission";
import axios from "../../utils/axios";
import Teams from "./Tracks/Teams";
import Submissions from "./Tracks/Submissions";
import Stage2Submissions from "./Stage2Submissions";
import Stage3Submissions from "./Stage3Submissions";
import ProblemStatementsSubmittedSPOC from "./ProblemStatementsSubmitted";
import { retrieve } from "./../../utils/store";
import EvaluatorsListing from "./EvaluatorsListing";

export default function Base() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    requireAuth().then((data) => {
      setUser(data.user);

      if (data.user.challenge_role !== "spoc") {
        return RouteHelper.redirectToRoleDashboard(data.user.challenge_role);
      }

      if (!retrieve("challenge")) {
        return RouteHelper.redirect("/spoc/challenges");
      }

      axios()
        .get("/tracks")
        .then((response) => {
          setTracks(response.data.data);
          setIsLoading(false);
        });
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header isSideBarPresent={true} />
      <div className="row" style={{ marginRight: "0px" }}>
        <SideBar sideBarData={SpocSidebarData} className="col-sm-3 col-md-2" />
        <div
          className="col-md-10 offset-md-2 col-sm-9 offset-sm-3"
          style={{ minHeight: "75.4vh" }}
        >
          <Routes>
            <Route
              path="/"
              element={<Dashboard user={user} tracks={tracks} />}
            />
            <Route
              path="tracks"
              element={<Tracks user={user} tracks={tracks} />}
            />
            
            {/* new code starts here */}
            <Route
              path="evaluators"
              element={<EvaluatorsListing user={user} tracks={tracks} />}
            />
             {/* end  */}
            <Route
              path="viewtracks"
              element={<SPOCSubmittedTracks user={user} tracks={tracks} />}
            />
            <Route
              path="viewproblems"
              element={<ProblemStatementsSubmittedSPOC user={user} tracks={tracks} />}
            />
            <Route path="tracks/teams/:track" element={<Teams />} />
            <Route path="tracks/submissions/:track" element={<Submissions />} />
            <Route
              path="problems"
              element={<Problems user={user} tracks={tracks} />}
            />
            <Route path="submissions/stage2" element={<Stage2Submissions />} />
            <Route path="submissions/stage3" element={<Stage3Submissions />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}
