import { useState } from "react";
import { MDBInput } from "mdb-react-ui-kit";

export default function Professional(props) {
  const [employed, setEmployed] = useState(
    props?.data?.professional_employed ?? null
  );

  const [isEdit, setIsEdit] = useState(!!props?.data?.id);

  return (
    <div>
      <h6 class="mb-2 pb-1 fw-bold">Employed </h6>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="professional_employed"
          id="professional_employed_yes"
          value={1}
          required={true}
          onChange={() => setEmployed(1)}
          defaultChecked={props?.data?.professional_employed == true}
        />
        <label class="form-check-label" htmlFor="professional_employed_yes">
          Yes
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="professional_employed"
          id="professional_employed_no"
          value={0}
          required={true}
          onChange={() => setEmployed(0)}
          defaultChecked={props?.data?.professional_employed === 0}
        />
        <label class="form-check-label" htmlFor={"professional_employed_no"}>
          No
        </label>
      </div>
      <br></br>
      <br></br>
      {employed == true && (
        <div>
          <h6 class="mb-2 pb-1 fw-bold">Company Name: </h6>
          <MDBInput
            label="Company Name"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name={"professional_employed_firm"}
            required={true}
            defaultValue={props?.data?.professional_employed_firm}
          />
          <br />
          <h6 className="mb-2 pb-1 fw-bold">Designation: </h6>
          <MDBInput
            label="Designation"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name={"professional_employed_designation"}
            required={true}
            defaultValue={props?.data?.professional_employed_designation}
          />
          <br />
          <h6 className="mb-2 pb-1 fw-bold">Job Title: </h6>
          <MDBInput
            label="Job Title"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name={"professional_employed_job_title"}
            required={true}
            defaultValue={props?.data?.professional_employed_job_title}
          />
          <br />
          <div>
            <h6 className="mb-2 pb-1 fw-bold">
              Upload ID card of the company employed (File Type: PDF, Max
              Size:10MB):
            </h6>
            <MDBInput
              required={!isEdit || props?.data?.professional_employed == false}
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name="professional_employed_id_card_file"
              accept={".pdf"}
            />
          </div>
          <br></br>
          <div>
            <h6 className="mb-2 pb-1 fw-bold">
              Upload ID proof issued by Government (File Type: PDF, Max
              Size:10MB):
            </h6>
            <MDBInput
              required={!isEdit}
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name="professional_govt_id_proof_file"
              accept={".pdf"}
            />
          </div>
          <br></br>
          <div>
            <h6 className="mb-2 pb-1 fw-bold">
              Upload address proof issued by Government (File Type: PDF, Max
              Size:10MB):
            </h6>
            <MDBInput
              required={!isEdit}
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name="professional_govt_address_proof_file"
              accept={".pdf"}
            />
          </div>
          <br></br>
        </div>
      )}
      {employed === 0 && (
        <div>
          <h6 className="mb-2 pb-1 fw-bold">Current Occupation: </h6>
          <MDBInput
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name={"professional_current_occupation"}
            required={true}
            defaultValue={props?.data?.professional_current_occupation}
          />
          <br />
          <div>
            <h6 className="mb-2 pb-1 fw-bold">
              Upload ID proof issued by Government (File Type: PDF, Max
              Size:10MB):
            </h6>
            <MDBInput
              required={!isEdit}
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name="professional_govt_id_proof_file"
              accept={".pdf"}
            />
          </div>
          <br></br>
          <div>
            <h6 className="mb-2 pb-1 fw-bold">
              Upload address proof issued by Government (File Type: PDF, Max
              Size:10MB):
            </h6>
            <MDBInput
              required={!isEdit}
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name="professional_govt_address_proof_file"
              accept={".pdf"}
            />
          </div>
          <br></br>
        </div>
      )}
    </div>
  );
}
