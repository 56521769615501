import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModalBody,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import auth from "../utils/auth";
import axios from "../utils/axios";
import "react-toastify/dist/ReactToastify.css";

export default function IdeaSubmission(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [idea, setIdea] = useState(props?.idea ?? {});

  useEffect(() => {
    if (!idea?.id && props.addSubmission !== true) {
      axios()
        .get(`/problems/${props.problem.id}/submission`)
        .then((response) => {
          setIdea(response.data.data);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (props.disabled) {
      return;
    }

    let data = new FormData(e.target);

    toast.promise(
      axios().post(
        idea?.id
          ? `/problems/submissions/${idea.id}`
          : `/problems/${props.problem.id}/submission`,
        data
      ),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {
            setIsSubmitting(false);
            props.setCurrentPs(false);
            props.setFetchProblems(true);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  if (isLoading) {
    return <div>Loading....</div>;
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCurrentPs(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4 ">{props.problem.title}</p>
            <form onSubmit={handleSubmit}>
              <h6 class="mb-2 pb-1 fw-bold">
                Title <span className="redColor">(Max 100 characters): </span>
              </h6>
              <MDBInput
                required={true}
                label="Title"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"title"}
                defaultValue={idea?.title}
              />
              <br></br>
              <h6 class="mb-2 pb-1">
                Description{" "}
                <span className="redColor">(Max 10000 characters):</span>{" "}
              </h6>
              <MDBTextArea
                rows={3}
                required={true}
                label="Description"
                group
                type="textarea"
                validate
                error="wrong"
                success="right"
                name={"description"}
                defaultValue={idea?.description}
              />
              <br />
              <h6 class="mb-2 pb-1 fw-bold">
                Abstract{" "}
                <span className="redColor">(Max 10000 characters):</span>{" "}
              </h6>
              <MDBTextArea
                rows={3}
                required={true}
                label="Abstract"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"abstract"}
                defaultValue={idea?.abstract}
              />
              <br />
              <div>
                <h6 className="mb-2 pb-1 fw-bold">
                  Upload Idea Document File{" "}
                  <span className="redColor">(PDF, Max size 10MB):</span>
                </h6>
                <MDBInput
                  required={idea?.idea_document_file ? false : true}
                  group
                  type="file"
                  validate={true}
                  error="wrong"
                  success="right"
                  name="idea_document_file"
                  accept=".pdf"
                />
                {idea?.idea_document_file ? (
                  <MDBBtn
                    color="primary"
                    className="col p-2 mt-2"
                    href={idea.idea_document_file}
                  >
                    View Submitted Idea Document
                  </MDBBtn>
                ) : (
                  ""
                )}
              </div>
              <br></br>
              <div>
                <h6 className="mb-2 pb-1 fw-bold">
                  Upload Other Document File{" "}
                  <span className="redColor">(PDF, Max size 10MB):</span>
                </h6>
                <MDBInput
                  group
                  type="file"
                  validate
                  error="wrong"
                  success="right"
                  name="other_document_file"
                  accept=".pdf"
                />
                {idea?.other_document_file ? (
                  <MDBBtn
                    color="primary"
                    className="col p-2 mt-2"
                    href={idea.other_document_file}
                  >
                    View Submitted Other Document
                  </MDBBtn>
                ) : (
                  ""
                )}
              </div>
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">
                Video URL<span className="redColor"> (Optional):</span>
              </h6>
              <MDBInput
                required={false}
                label="Video URL"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"video_url"}
                defaultValue={idea?.video_url}
              />
              <br></br>
              {!props.disabled && (
                <div className="text-center">
                  <MDBBtn color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting...." : "Submit"}
                  </MDBBtn>
                </div>
              )}
            </form>
          </div>
        </MDBCol>
      </MDBRow>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MDBModalBody>
  );
}
