import React, { useEffect } from "react";
import axios from "./axios";
import { api_token_store_name, retrieve, store } from "./store";
import RouteHelper from "./routeHelper";

export default function auth() {
  let isAuth =
    retrieve(api_token_store_name()) != null &&
    retrieve(api_token_store_name()) != "" &&
    retrieve(api_token_store_name()) != "null";

  const fetchUser = async (id = null, redirect_verification = true) => {
    let user;

    try {
      user = await (await axios().get("/user" + (id ? `s/${id}` : ""))).data
        .data;
      isAuth = true;

      if (!user.email_verified && redirect_verification) {
        RouteHelper.redirect("/verify");
      }

      return { isAuth, user };
    } catch (error) {
      store(api_token_store_name(), "");
      isAuth = false;
      user = null;

      return { isAuth, user };
    }
  };

  const fetchEvaluators = async () => {
    try {
      const response = await axios().get("/evaluators");
      const evaluatorsData = response.data;
      return evaluatorsData;
    } catch (error) {
      console.error("Error fetching evaluators data:", error);
      return [];
    }
  };

  // const approveEvaluator = async (evaluatorId) => {
  //   try {
  //     const response = await axios().post(`/evaluators/approve/${evaluatorId}`);

  //     return response.data;
  //   } catch (error) {
  //     console.error('Error approving evaluator:', error);
  //     throw error;
  //   }
  // };

  const approveEvaluator = async (evaluatorId, currentStatus) => {
    try {
      const response = await axios().post(`/evaluators/approve/${evaluatorId}`);
      const successMessage =
        currentStatus === 1
          ? "Evaluator disapproved successfully"
          : "Evaluator approved successfully";
      return { success: true, message: successMessage };
    } catch (error) {
      console.error("Error updating evaluator status:", error);
      throw error;
    }
  };

  const login = (data) => {
    return axios().post("/login", data);
  };

  const register = (data) => {
    return axios().post("/register", data);
  };

  const sendVerificationEmail = () => {
    return axios().post("/user/send_verification");
  };

  const setToken = async (data) => {
    if (
      data.user.role === "evaluator" &&
      !window.confirm(
        "The information shared by way of documents are confidential, intended only for you and is being shared as a part of evaluation process for Onion Grand Challenge 2022. The documents may contain information that is proprietary, privileged, confidential nonpublic or otherwise exempt from disclosure. Any dissemination, distribution or copying of the same is strictly prohibited."
      )
    ) {
      return;
    }
    await store(api_token_store_name(), data.token);

    if (data.user.email_verified) {
      RouteHelper.redirect("/challenges");
    } else {
      RouteHelper.redirect("/verify");
    }
  };

  const logout = async (redirectUrl = "/", token_expired = false) => {
    try {
      if (!token_expired) {
        await axios().post("/logout");
      }
    } finally {
      localStorage.setItem("stage", "");
      store(api_token_store_name(), "");
      store("challenge", "");

      RouteHelper.redirect(redirectUrl);
    }
  };

  function checkValidChallenge(challenge) {
    const challenges = [1, 2];

    return challenges.indexOf(parseInt(challenge.toString())) !== -1;
  }

  function setChallenge(
    challenge,
    challenge_registered = true,
    redirect = true,
    user = {}
  ) {
    if (!checkValidChallenge(challenge)) {
      return RouteHelper.redirect("/challenges");
    }

    store("challenge", challenge);

    if (challenge_registered === false) {
      return RouteHelper.redirect("/challenges/register");
    }

    if (redirect) {
      if (user.role === "spoc") {
        return RouteHelper.redirect("/spoc");
      }

      if (user.role === "evaluator") {
        return RouteHelper.redirect("/evaluator");
      }

      return RouteHelper.redirect("/team/stages");
    }
  }

  return {
    login,
    setToken,
    logout,
    fetchUser,
    fetchEvaluators,
    approveEvaluator,
    isAuth,
    register,
    sendVerificationEmail,
    setChallenge,
    checkValidChallenge,
  };
}

export const {
  login,
  setToken,
  logout,
  fetchUser,
  fetchEvaluators,
  approveEvaluator,
  isAuth,
  register,
  sendVerificationEmail,
  setChallenge,
  checkValidChallenge,
} = auth();
