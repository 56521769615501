import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModalBody,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import RouteHelper from "../../../utils/routeHelper";

export default function Startup(props) {
  const previous =
    props.previous?.category === "startup" ? props.previous : false;

  const [startupRegistered, setStartupRegistered] = useState(
    previous ? previous?.startup_registered : false
  );
  const [startupRegisteredIndia, setStartupRegisteredIndia] = useState(
    previous ? previous?.startup_registered_under_india : false
  );
  const [startupRole, setStartupRole] = useState(
    previous ? previous?.role_in_startup_company_msme : false
  );

  const [isLoading, setIsLoading] = useState(false);

  function handleFormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("category", "startup");
    toast.promise(
      axios().post(`/challenges/register/${props.track.id}`, data),
      {
        pending: {
          render() {
            setIsLoading(true);
            return "Registering......";
          },
        },
        success: {
          render() {
            setIsLoading(false);
            RouteHelper.redirect("/dashboard");
            return "Team registered successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsLoading(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              console.log(data.errors);

              Object.entries(data.errors).forEach(([, value]) => {
                value.forEach((v) =>
                  toast.error(v.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                );
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCategory(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4 mt-4">
              {props.track.name} Registration
            </p>
            <form onSubmit={handleFormSubmit}>
              <h6 className="mb-2 pb-1 fw-bold">Team Name: </h6>
              <MDBInput
                required={true}
                label="Name"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name="name"
                defaultValue={previous ? previous?.name : ""}
              />
              <br />
              <h6 class="mb-2 pb-1 fw-bold">Startup / Company Name: </h6>
              <MDBInput
                label="Startup Name"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"startup_company_msme_name"}
                required={true}
                defaultValue={
                  previous ? previous?.startup_company_msme_name : ""
                }
              />
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">Description of Business: </h6>
              <MDBTextArea
                rows={4}
                label="Description"
                group
                type="textarea"
                validate
                error="wrong"
                success="right"
                name={"startup_description"}
                required={true}
                defaultValue={previous ? previous?.startup_description : ""}
              />
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">Founded in Year </h6>
              <MDBInput
                label="Year"
                group
                type="number"
                min={2000}
                max={2023} //TODO: Update max year
                validate
                error="wrong"
                success="right"
                name={"startup_company_msme_founded_year"}
                required={true}
                defaultValue={
                  previous ? previous?.startup_company_msme_founded_year : ""
                }
              />
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">Is Startup Registered?</h6>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="startup_registered"
                  id="startup_registered_yes"
                  value={1}
                  required={true}
                  defaultChecked={startupRegistered}
                  onChange={() => setStartupRegistered(true)}
                />
                <label
                  class="form-check-label"
                  htmlFor="startup_registered_yes"
                >
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="startup_registered"
                  id="startup_registered_no"
                  value={0}
                  required={true}
                  defaultChecked={!startupRegistered}
                  onChange={() => setStartupRegistered(false)}
                />
                <label class="form-check-label" htmlFor="startup_registered_no">
                  No
                </label>
              </div>

              <br></br>
              <br></br>
              {startupRegistered && (
                <div>
                  <h6 class="mb-2 pb-1 fw-bold">
                    Upload Registration / Incorporation Certificate (File Type:
                    PDF, Max Size:10MB)
                    {/*{previous?.startup_registration_certificate_file*/}
                    {/*  ? "(Leave empty to use previous certificate file)"*/}
                    {/*  : ""}*/}:
                  </h6>
                  <MDBInput
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    name={"startup_registration_certificate_file"}
                    accept={"application/pdf"}
                    required={true}
                  />
                  <br></br>
                  <h6 class="mb-2 pb-1 fw-bold">
                    Is Startup Registered under Startup India?
                  </h6>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="startup_registered_under_india"
                      id="startup_registered_under_india_yes"
                      value={1}
                      required={true}
                      defaultChecked={startupRegisteredIndia}
                      onChange={() => setStartupRegisteredIndia(true)}
                    />
                    <label
                      class="form-check-label"
                      htmlFor="startup_registered_under_india_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="startup_registered_under_india"
                      id="startup_registered_under_india_no"
                      value={0}
                      defaultChecked={!startupRegisteredIndia}
                      required={true}
                      onChange={() => setStartupRegisteredIndia(false)}
                    />
                    <label
                      class="form-check-label"
                      htmlFor="startup_registered_under_india_no"
                    >
                      No
                    </label>
                  </div>

                  <br></br>
                  <br></br>
                  {startupRegisteredIndia && (
                    <div>
                      <h6 class="mb-2 pb-1 fw-bold">
                        Upload Your Startup India Registration Certificate (File
                        Type: PDF, Max Size:10MB)
                        {/*{previous?.startup_india_registration_certificate_file*/}
                        {/*  ? "(Leave empty to use previous certificate file)"*/}
                        {/*  : ""}*/}:
                      </h6>
                      <MDBInput
                        group
                        type="file"
                        validate
                        error="wrong"
                        success="right"
                        name={"startup_india_registration_certificate_file"}
                        accept={"application/pdf"}
                        required={true}
                      />
                      <br></br>
                    </div>
                  )}
                </div>
              )}
              {startupRegistered === false && (
                <div>
                  <h6 className="mb-2 pb-1 fw-bold">
                    Upload Government ID Proof (File Type: PDF, Max Size:10MB)
                    {/*{previous?.startup_govt_id_proof_file*/}
                    {/*  ? "(Leave empty to use previous ID proof file)"*/}
                    {/*  : ""}*/}:
                  </h6>
                  <MDBInput
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    name={"startup_govt_id_proof_file"}
                    accept={"application/pdf"}
                    required={true}
                  />
                  <br></br>
                  <h6 className="mb-2 pb-1 fw-bold">
                    Upload Address Proof (File Type: PDF, Max Size:10MB)
                    {/*{previous?.startup_address_proof_file*/}
                    {/*  ? "(Leave empty to use previous address proof file)"*/}
                    {/*  : ""}*/}:
                  </h6>
                  <MDBInput
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    name={"startup_address_proof_file"}
                    accept={"application/pdf"}
                    required={true}
                  />
                  <br></br>
                </div>
              )}
              <h6 className="mb-2 pb-1 fw-bold">Business Type:</h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"startup_company_msme_business_type"}
                  defaultValue={
                    previous ? previous?.startup_company_msme_business_type : ""
                  }
                >
                  <option value="manufacturing">Manufacturing</option>
                  <option value="service">Service</option>
                  <option value="both">Both</option>
                </select>
              </div>
              <br />

              <br />
              <h4>Team Leader:</h4>
              <h6 className="mb-2 pb-1 fw-bold">Education: </h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"education_info"}
                  defaultValue={
                    previous ? previous?.members[0]?.education_info : ""
                  }
                >
                  <option value="Diploma">Diploma</option>
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                  <option value="Ph.D">PhD</option>
                </select>
              </div>
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Role in startup:</h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"role_in_startup_company_msme"}
                  defaultValue={
                    previous
                      ? previous?.members[0]?.role_in_startup_company_msme
                      : ""
                  }
                  onChange={(e) => setStartupRole(e.target.value)}
                >
                  <option value="CCO">CCO</option>
                  <option value="CTO">CTO</option>
                  <option value="Founder">Founder</option>
                  <option value="Co Founder">Co Founder</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <br />
              {startupRole &&
                startupRole !== "CCO" &&
                startupRole !== "CTO" &&
                startupRole !== "Founder" &&
                startupRole !== "Co Founder" && (
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Specify role in startup:{" "}
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      name="role_in_startup_company_msme"
                      defaultValue={
                        previous
                          ? previous?.members[0]?.role_in_startup_company_msme
                          : ""
                      }
                    />
                    <br />
                  </div>
                )}
              <div className="text-center">
                <MDBBtn color="primary" disabled={isLoading}>
                  {isLoading ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBModalBody>
  );
}
