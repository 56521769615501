import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { MDBBtn } from "mdb-react-ui-kit";
import download from "js-file-download";

export default function Dashboard(props) {
  const [stats, setStats] = useState([]);

  function handleDownload(e) {
    e.preventDefault();
    axios()
      .post(
        "/evaluator/export",
        {},
        {
          responseType: "blob",
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      )
      .then((response) => {
        download(response.data, "grand_challenge_evaluator_progress.xlsx");
      })
      .catch((error) => {
        alert("The file couldn't be downloaded");
      });
  }

  function handleDownloadEvaluations(e) {
    e.preventDefault();
    axios()
      .post(
        "/evaluations/export",
        {},
        {
          responseType: "blob",
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      )
      .then((response) => {
        download(response.data, "grand_challenge_evaluations.xlsx");
      })
      .catch((error) => {
        alert("The file couldn't be downloaded");
      });
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Registrations</h1>
          </div>
          <div className="d-flex row offset-9 col-md-3 justify-end">
            <MDBBtn
              class="mb-2 ripple ripple-surface btn btn-primary"
              color="secondary"
              onClick={(e) => handleDownloadEvaluations(e)}
            >
              Export Evaluations
            </MDBBtn>
            <MDBBtn color="primary" onClick={(e) => handleDownload(e)}>
              Export Evaluator Progress
            </MDBBtn>
          </div>
        </div>
        {Object.entries(props.tracks).map(([key, category]) => (
          <div key={key}>
            <div className="row mt-2 mb-4">
              <div className="col-md-12 text-center">
                <span class="btnTrack">{key}</span>
              </div>
            </div>

            <div className="row mt-4 mb-4">
              {category.map((track, index) => (
                <div key={index} className="col-md-4 text-center mt-4">
                  <div className="trackBox">
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h3>{track.name}</h3>
                    <h4>
                      <span>Registered:</span> {track.registrations}
                    </h4>
                    <div className="row justify-content-center">
                      <h5>
                        {" "}
                        Idea Submitted: <span>{track.submissions_count}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
