import Header from "../../../components/Header/Header";
import SideBar from "../../../components/SideBar";
import { SidebarData } from "../../../components/Team/SideBar/ChallengesSidebarData";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import Contact from "../ContactUs";
import { useEffect, useState } from "react";
import requireAuth from "../../../utils/requireAuth";
import RouteHelper from "../../../utils/routeHelper";
import Loading from "../../../components/Loading";
import Profile from "../Profile";
import Challenges from "./Challenges";
import EvaluatorChallenges from "../../Evaluator/Challenges/Challenges";
import SubmitIdea from "./SubmitIdea";
import { Submissions } from "./Submissions";
import RegisterTeam from "../RegisterTeam";
import { store } from "../../../utils/store";
import { Teams } from "./Teams";
import { FaUsers } from "react-icons/fa";
import Video from "./Video";
import Announcement from "./Announcement";

export default function ChalllengesBase() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    requireAuth().then((data) => {
      setUser(data.user);
      if (location.pathname !== "/challenges/register") {
        store("challenge", "");
        store("stage", "");
      }

      if (
        data.user.challenge_role !== "participant" &&
        data.user.challenge_role !== "spoc" &&
        data.user.challenge_role !== "evaluator"
      ) {
        return RouteHelper.redirectToRoleDashboard(data.user.challenge_role);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header isSideBarPresent={true} />
      <div className="row" style={{ marginRight: "0px" }}>
        {user.role === "user" && (
          <SideBar sideBarData={SidebarData} className="col-sm-3 col-md-2" />
        )}
        {(user.role === "spoc" || user.role === "evaluator") && (
          <SideBar
            sideBarData={[
              {
                title: "Challenges",
                path: "/challenges",
                icon: <FaUsers />,
              },
            ]}
            className="col-sm-3 col-md-2"
          />
        )}
        <div
          className="col-md-10 offset-md-2 col-sm-9 offset-sm-3"
          style={{ minHeight: "75.4vh" }}
        >
          {user.role === "user" && (
            <Routes>
              <Route path="/" element={<Challenges user={user} />} />
              <Route path="/submit" element={<SubmitIdea user={user} />} />
              <Route
                path="/submissions"
                element={<Submissions user={user} />}
              />
              <Route path="/teams" element={<Teams user={user} />} />
              <Route path="/contact" element={<Contact user={user} />} />
              <Route path="/profile" element={<Profile user={user} />} />
              <Route path="/register" element={<RegisterTeam user={user} />} />
              <Route path="/videos" element={<Video />} />
              <Route path="/announcement" element={<Announcement />} />
            </Routes>
          )}

          {user.role === "spoc" && (
            <Routes>
              <Route path="/" element={<Challenges user={user} />} />
              <Route path="/submit" element={<SubmitIdea user={user} />} />
              <Route
                path="/submissions"
                element={<Submissions user={user} />}
              />
              <Route path="/teams" element={<Teams user={user} />} />
              <Route path="/contact" element={<Contact user={user} />} />
              <Route path="/profile" element={<Profile user={user} />} />
            </Routes>
          )}

          {user.role === "evaluator" && (
            <Routes>
              <Route path="/" element={<EvaluatorChallenges user={user} />} />
              <Route path="/profile" element={<Profile user={user} />} />
            </Routes>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
