export default class RouteHelper {
  static redirect(route) {
    window.location.href = route;
  }

  static redirectToRoleDashboard(role) {
    if (role === "admin") {
      return this.redirect("/admin");
    }

    if (role === "participant") {
      return this.redirect("/team");
    }

    // if (role === "spoc") {
    //   return this.redirect("/spoc");
    // }
  

    if (role === "spoc") {
        return this.redirect("/challenges");
      }

    if (role === "evaluator") {
      return this.redirect("/evaluator");
    }
  }
}
