import React from "react";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import auth from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteHelper from "../../utils/routeHelper";
import SelectSearch from "react-select-search";
import { useEffect } from "react";
import axios from "../../utils/axios";

export default function Register() {
  
  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bhopal",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "The Dadra and Nagar Haveli and Daman and Diu",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const [state, setState] = useState("Andaman and Nicobar Islands");
  const [district, setDistrict] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // auth()
    //   .fetchUser()
    //   .then((response) => {
    //     if (response.isAuth) {
    //       RouteHelper.redirect("/dashboard");
    //     }
    //   });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    let data = new FormData(e.target);
    data.append("city", district);

    toast.promise(auth().register(data), {
      pending: {
        render() {
          setIsLoading(true);
          return "Registering......";
        },
      },
      success: {
        render({ data }) {
          setIsLoading(false);
          setTimeout(() => {
            auth().setToken(data.data);
          }, 5000);
          return "Registered successfully! Please check your email for the verification email.";
        },
      },
      error: {
        render({ data }) {
          setIsLoading(false);
          let status = data.response.status;
          data = data.response.data;
          if (status === 422) {
            Object.entries(data.errors).forEach(([key, value]) => {
              value.forEach((v) =>
                toast.error(v.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              );
            });

            return "There were errors in some fields";
          } else {
            return "Something went wrong!";
          }
        },
      },
    });
  }

  function getDistricts() {
    return new Promise((resolve, reject) => {
      axios()
        .get(`districts/${state}`)
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  }

  return (
    <div>
    
    <div className="d-flex align-items-center justify-content-center" style={{position:'relative'}}>
    <div style={{
      position:'absolute',
      content:'',
      height:'946px',
      width:'100%',
      backgroundColor:'#000000',
      opacity:'0.2',
    }}></div>
      <MDBContainer className="mt-4 px-2 mb-4">
        <MDBRow className="row d-flex justify-content-center">
          <MDBCol md="6">
            <MDBCard className="my-4">
              <MDBCardBody className="shadow">
                <h3 className="text-center"> Welcome to Grand Challenge </h3>

                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="text-dark">
                      <h6 className="mb-2 pb-1 fw-bold">Name: </h6>
                      <MDBInput
                        required={true}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        name="name"
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Email: </h6>
                      <MDBInput
                        required={true}
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        name="email"
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Mobile Number: </h6>
                      <MDBInput
                        required={true}
                        icon="tag"
                        group
                        type="number"
                        validate
                        error="wrong"
                        success="right"
                        name="phone"
                      />
                    </div>
                    <br></br>
                    <div className="col-md-6 mb-4">
                      <h6 className="mb-2 pb-1 fw-bold">Gender: </h6>

                      <div className="form-check form-check-inline">
                        <input
                          required={true}
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="femaleGender"
                          value="female"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="femaleGender"
                        >
                          Female
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          required={true}
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="maleGender"
                          value="male"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="maleGender"
                        >
                          Male
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          required={true}
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="otherGender"
                          value="other"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="otherGender"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <h6 className="mb-2 pb-1 fw-bold">State: </h6>
                        <select
                          required={true}
                          className="form-control form-select"
                          name={`state`}
                          onChange={(e) => setState(e.target.value)}
                        >
                          {states.map((s) => (
                            <option value={s}>{s}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <h6 className="mb-2 pb-1 fw-bold">City: </h6>
                        <SelectSearch
                          search={false}
                          value={district}
                          onChange={(value, obj) => {
                            setDistrict(value);
                          }}
                          debounce={500}
                          getOptions={getDistricts}
                          name="district"
                          placeholder={"Please select a city"}
                          options={[
                            { name: "Please select a city", value: "" },
                          ]}
                        />
                      </div>
                      <div className="col-md-12">
                        <h6 className="mb-2 pb-1 fw-bold">Password: </h6>
                        <MDBInput
                          required={true}
                          icon="password"
                          group
                          type="password"
                          validate
                          error="wrong"
                          success="right"
                          name="password"
                        />
                        <br></br>
                        <h6 className="mb-2 pb-1 fw-bold">
                          Password Confirmation:{" "}
                        </h6>
                        <MDBInput
                          required={true}
                          icon="password"
                          group
                          type="password"
                          validate
                          error="wrong"
                          success="right"
                          name="password_confirmation"
                        />
                        <br></br>
                      </div>
                    </div>
                  </div>
                  <div className="text-center py-3">
                    <MDBBtn
                      className="shadow"
                      color="danger"
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? "Registering...." : "Register"}
                    </MDBBtn>
                  </div>
                </form>

                {/*//TODO Remove when disabling again*/}
                {/*<h4 className="flex justify-center align-content-center">*/}
                {/*  Sorry, Registrations for the current Grand Challenge has*/}
                {/*  ended!*/}
                {/*</h4>*/}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    </div>
  );
}
