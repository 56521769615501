import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import "react-toastify/dist/ReactToastify.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { toast } from "react-toastify";

export default function Evaluators(props) {
  const [evaluators, setEvaluators] = useState([]);

  useEffect(() => {
    // Fetch evaluator data from an API endpoint
    axios()
      .get("/evaluators")
      .then((response) => {
        setEvaluators(response.data);
      })
      .catch((error) => {
        console.error("Error fetching evaluators:", error);
      });
  }, []);

  function handleApproval(evaluator, approve) {
    const newStatus = approve ? 1 : 0;

    let c = window.confirm(
      `Are you sure you want to ${approve ? "approve" : "reject"} ${
        evaluator.name
      }`
    );

    if (c) {
      axios()
        .post(
          `/evaluators/registrations/${evaluator.id}/${
            approve ? "approve" : "reject"
          }`,
          { approval_status: newStatus }
        )
        .then((response) => {
          const updatedEvaluators = evaluators.filter((e) => {
            return e.id !== evaluator.id;
          });

          setEvaluators(updatedEvaluators);
          const successMessage =
            newStatus === 1
              ? "Evaluator approved successfully"
              : "Evaluator disapproved successfully";
          toast.success(successMessage);
        })
        .catch((error) => {
          console.error("Error updating evaluator status:", error);
          toast.error("Error updating evaluator status");
        });
    }
  }

  return (
    <section className="evaluators-table mt-4 mb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Evaluators Details</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Evaluator Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Designation</th>
                    <th>Organization</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluators.map((evaluator) => (
                    <tr key={evaluator.id}>
                      <td>{evaluator.name}</td>
                      <td>{evaluator.email}</td>
                      <td>{evaluator.phone}</td>
                      <td>{evaluator.designation}</td>
                      <td>{evaluator.organization}</td>
                      <td>
                        {evaluator.cv && (
                          <MDBBtn
                            color="primary"
                            href={evaluator.cv}
                            target={"_blank"}
                            className="col p-2  m-2"
                          >
                            View CV
                          </MDBBtn>
                        )}
                        {evaluator.photo && (
                          <MDBBtn
                            target={"_blank"}
                            color="secondary"
                            className="col p-2 m-2"
                            href={evaluator.photo}
                          >
                            View Photo
                          </MDBBtn>
                        )}

                        <br />
                        <MDBBtn
                          onClick={() => handleApproval(evaluator, true)}
                          color="success"
                          className="col p-2  m-2"
                        >
                          Approve
                        </MDBBtn>
                        <MDBBtn
                          className="col p-2  m-2"
                          onClick={() => handleApproval(evaluator, false)}
                          color="danger"
                        >
                          Reject
                        </MDBBtn>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
