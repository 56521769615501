import React from "react";
import { FaUsers, FaLightbulb, FaLock, FaChessKing } from "react-icons/fa";

export const SidebarData = [
  {
    title: "Grand Challenges",
    path: "/challenges",
    icon: <FaChessKing />,
  },
  {
    title: "Evaluation Dashboard",
    path: "/evaluator",
    icon: <FaUsers />,
  },
  {
    title: "Allocated Ideas",
    path: "/evaluator/evaluate/",
    icon: <FaLightbulb />,
  },
  {
    title: "Reset Password",
    path: "/reset",
    icon: <FaLock />,
  },
];
