// export default function Video() {
//     return <div>HELLO</div>;
// }


import React from 'react';

export default function Video() {
	const headingStyle = {
	   	color: '#ffffff',
	    backgroundColor: '#111342',
	    padding: '14px',
	    fontWeight: '700',
	    fontSize: '25px',
	    textTransform:'uppercase'
	}
	const videoBox = {
		padding: '10px',
		width: '100%',
		height: '315px',
		border:'4px solid #f49602',
		borderRadius: '5px',
	}
	const textAlignment = {
		textAlign:'center',
		padding: '15px',
		fontWeight: '400',
	    fontSize: '25px',
	    textTransform:'capitalize',
	    color: '#ffffff',
	    backgroundColor: '#111342',
	}
    return (
        <div className="container pt-5 pb-5">
        	<div className="row pb-5">
        		<div className="col-md-12">
        			 <h5 className="card-title text-center mb-2" style={ headingStyle }>Video</h5>
        		</div>
        	</div>
        	<div className="row">
        		<div className="col-md-6">
        			 <iframe style={videoBox} src="https://www.youtube.com/embed/u0IBocHHOlw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>

        			 </iframe>
        			 <p style={textAlignment}>
        			 	<strong>Tomato Grand Challenge: Vertical 1 and Vertical 2 </strong>
        			 </p>
        		</div>
        		<div className="col-md-6">
        			 <iframe style={videoBox} src="https://www.youtube.com/embed/aY3fPB28dzg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        			<p style={textAlignment}>
        			 	<strong>Tomato Grand Challenge: Vertical 3 </strong>
        			 </p>
        		</div>
        	</div>	
        </div>   
    );
}

