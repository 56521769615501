import { MDBInput } from "mdb-react-ui-kit";

export default function StudentFaculty(props) {
  return (
    <div>
      <h6 className="mb-2 pb-1 fw-bold">Enrollment/ID Card Number: </h6>
      <MDBInput
        required={true}
        label="Enrollment/ID Card Number"
        group
        type="text"
        validate
        error="wrong"
        success="right"
        name={`enrollment_number`}
        defaultValue={props?.data?.enrollment_number}
      />
      <br />
    </div>
  );
}
