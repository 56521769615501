import React, { useEffect, useState } from "react";
import { retrieve } from "../../utils/store";

export default function Header(props) {
  return (
    <section className="row" style={{ marginRight: "0px" }}>
      {/*<nav
        className={
          "navbar navbar-expand-lg navbar-light bg-nav " +
          (props.isSideBarPresent ? "col-md-10 offset-md-2" : "col-md-12")
        }
      >*/}
      <nav className="navbar navbar-expand-lg navbar-light bg-nav col-md-12">
        {/*className="navbar navbar-expand-lg navbar-light bg-nav col-md-10 offset-md-2 col-md-12">*/}
        {/*<img className="overImg ml-2" src="/onion-2.png" alt="image" />*/}
        <img
          className="hamburgerlogo"
          src="/hamburger-menu.png"
          alt="image"
          style={{ right: "90%" }}
          onClick={() => {
            props.setShowSideBar(!props.showSideBar);
          }}
        />
        {/*} <img
          className="overImg-1"
          src="/onion-1.png"
          alt="image"
          style={{ right: props.isSideBarOpen ? "55%" : "90%" }}
        />*/}
        {/*} {props.isSideBarPresent ? (
          <img className="overImg-4 ml-2" src="/AICTE-logo.png" alt="image" />
        ) : (
          <img className="overImg ml-2" src="/tomatoes-2.png" alt="image" />
        )}*/}

        {/*{props.isSideBarPresent ? (
          ""
        ) : (
          <img className="overImg-2" src="/onion-4.png" alt="image" />
        )}*/}

        <div className="container-fluid">
          {/*{!props.isSideBarPresent && (
            <a className="navbar-brand" href="#">
              <img className="img-fluid" src="/logo.png" alt="logo" />
            </a>
          )}*/}
          <a className="navbar-brand" href="#" style={{ paddingLeft: "17%" }}>
            <img className="img-fluid" src="/logo.png" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-lg-center justify-content-xl-center navContent"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#">
                  <h2>GRAND CHALLENGES</h2>
                  <p>{/*for the development of
                    <br />*/}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {retrieve("challenge") &&
        (retrieve("challenge") == 1 ? (
          <div className="secnav">
            <span>
              Technologies for Primary Processing, Storage and Valorization of
              Onions
            </span>
            {/*<img className="img-fluid" src="/timage.png" alt="Banner Image"/>*/}
            <img
              className="img-fluid"
              src="/onion-new12.png"
              alt="Banner Image"
            />
          </div>
        ) : (
          <div className="secnav">
            <span>
              Technologies for Primary Processing, Storage and Valorization of
              Tomatoes
            </span>
            {/*<img className="img-fluid" src="/timage.png" alt="Banner Image"/>*/}
            <img
              className="img-fluid"
              src="/tomato-banner.png"
              alt="Banner Image"
            />
          </div>
        ))}
    </section>
  );
}
