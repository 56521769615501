import React from "react";
import MultiSelectAll from "./MultiSelectAll";

import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBTextArea,
  MDBCheckbox,
} from "mdb-react-ui-kit";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectSearch from "react-select-search";
import axios from "../../utils/axios";

export default function EvaluatorRegistration() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [willingToParticipate, setWillingToParticipate] = useState(false);

  const [showOtherReferral, setShowOtherReferral] = useState(false);
  const [otherReferralName, setOtherReferralName] = useState("");

  const states = [
    "Select Your State",
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bhopal",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "The Dadra and Nagar Haveli and Daman and Diu",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const experts = [
    "None",
    "Dr. Abhay Jere",
    "Dr. Nagendra Rai",
    "Dr. S K Singh",
    "Dr. Sridevi Annapurna Singh",
    "Dr. Surinder K Tikoo",
    "Dr. Harinder Singh Oberoi",
    "Dr. M Loganathan",
    "Shri Rajesh Kumar Mishra",
    "Dr. K. Guite",
    "Shri Sanjay Kumar Singh",
    "Dr. Sudhakar Pandey",
    "Sh. Anand Kumar Singh",
    "Others",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    let data = new FormData(e.target);
    data.append("city", district);
    data.append(
      "preference_vertical_ids",
      selectedOptions
        .map((option) => option.id)
        .filter((option) => option != null)
        .join(",")
    );

    toast.promise(axios().post("evaluators/register", data), {
      pending: {
        render() {
          setIsLoading(true);
          return "Submitting......";
        },
      },
      success: {
        render({ data }) {
          setIsLoading(false);
          return "Submitted successfully! Upon further evaluation, our team will notify you";
        },
      },
      error: {
        render({ data }) {
          setIsLoading(false);
          let status = data.response.status;
          data = data.response.data;
          if (status === 422) {
            Object.entries(data.errors).forEach(([key, value]) => {
              value.forEach((v) =>
                toast.error(v.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                })
              );
            });

            return "There were errors in some fields";
          } else {
            return "Something went wrong!";
          }
        },
      },
    });
  }

  function getDistricts() {
    return new Promise((resolve, reject) => {
      axios()
        .get(`districts/${state}`)
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  }

  function handleReferralChange(e) {
    const selectedValue = e.target.value;
    if (selectedValue === "Others") {
      setShowOtherReferral(true);
    } else {
      setShowOtherReferral(false);
    }
  }



  return (
    <div className="d-flex align-items-center justify-content-center mb-4 login-card">
      <MDBContainer className="mt-4 mb-4 px-2">
        <MDBRow className="row d-flex justify-content-center mb-4">
          <MDBCol md="6">
            <MDBCard className="mt-4">
              <MDBCardBody className="shadow">
                <h3 className="text-center mb-4 p-2 bg-nav text-white rounded">
                  Evaluator Registration
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <h6>Name:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="name"
                    placeholder="Your Name"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Gender:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      required={true}
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="maleGender"
                      value="male"
                    />
                    <label className="form-check-label" htmlFor="maleGender">
                      Male
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      required={true}
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="femaleGender"
                      value="female"
                    />
                    <label className="form-check-label" htmlFor="femaleGender">
                      Female
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      required={true}
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="otherGender"
                      value="other"
                    />
                    <label className="form-check-label" htmlFor="otherGender">
                      Other
                    </label>
                  </div>
                  <br />
                  <br />

                  <div className="d-flex">
                    <h6>Email:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    name="email"
                    placeholder="Your Email"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Alternate Email:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    name="alternate_email"
                    placeholder="Your Alternate Email"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Contact Number:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="tel"
                    validate
                    error="wrong"
                    success="right"
                    name="phone"
                    placeholder="Your Contact Number"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Organization:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="organization"
                    placeholder="Your Organization"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Designation:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="designation"
                    placeholder="Your Designation"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Total Experience (in Years):</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="number"
                    min={0}
                    max={90}
                    validate
                    error="wrong"
                    success="right"
                    name="total_experience"
                    placeholder="Total Experience (in Years)"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Address:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBTextArea
                    required={true}
                    rows={2}
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="address"
                    placeholder="Your Address"
                  />
                  <br />

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="d-flex">
                        <h6>State:</h6>
                        <h6 className="text-danger">*</h6>
                      </div>
                      <select
                        required={true}
                        className="form-control form-select"
                        name={`state`}
                        onChange={(e) => setState(e.target.value)}
                      >
                        {states.map((s) => (
                          <option value={s}>{s}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="d-flex">
                        <h6>City:</h6>
                        <h6 className="text-danger">*</h6>
                      </div>
                      <SelectSearch
                        search={false}
                        value={district}
                        onChange={(value, obj) => {
                          setDistrict(value);
                        }}
                        debounce={500}
                        getOptions={getDistricts}
                        name="district"
                        placeholder={"Please select a city"}
                        options={[{ name: "Please select a city", value: "" }]}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <h6>Preference for Evaluation:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MultiSelectAll
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />

                  <br/>

                  <div className="d-flex">
                    <h6>Upload CV:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    name="cv"
                    accept={".pdf"}
                  />
                  <br />

                  <div className="d-flex">
                    <h6>LinkedIn Profile Link:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>
                  <MDBInput
                    required={true}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="linkedin_profile"
                    placeholder="Your LinkedIn Profile Link"
                  />
                  <br />

                  <div className="d-flex">
                    <h6>Upload Photo:</h6>
                    <h6 className="text-danger">*</h6>
                  </div>

                  <MDBInput
                    required={true}
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    accept=".png,.jpg,.jpeg"
                    name="photo"
                  />
                  <br />

                  <MDBCheckbox
                    name="willingness_to_participate"
                    value="1"
                    id="flexCheckDefault"
                    label="Willing to evaluate in Tomato Challenge 2023"
                  />

                  <br />

                  <div className="col-md-6 mb-4">
                    <div className="d-flex">
                      <h6>Referred by:</h6>
                      <h6 className="text-danger">*</h6>
                    </div>
                    <select
                      required={true}
                      className="form-control form-select"
                      name={`referred_by`}
                      onChange={handleReferralChange}
                    >
                      {experts.map((s) => (
                        <option value={s}>{s}</option>
                      ))}
                    </select>
                  </div>

                  {showOtherReferral && (
                  <div className="col-md-6 mb-4">
                    <div className="d-flex">
                      <h6>Name of Referrer:</h6>
                      <h6 className="text-danger">*</h6>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="other_referrer_name"
                      value={otherReferralName}
                      onChange={(e) => setOtherReferralName(e.target.value)}
                      required={true}
                    />
                  </div>
                )}

                  <div className="text-center mt-3">
                    <MDBBtn
                      className="shadow"
                      color="danger"
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? "Submitting...." : "Submit"}
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
