import React from "react";
import {
  FaUsers,
  FaUser,
  FaUserTie,
  FaLightbulb,
  FaPhone,
  FaUserCircle,
  FaChessKing,
  FaBell,
  FaFilePdf,
} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf } from '@fortawesome/free-solid-svg-icons';


function Announcement() {


  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h5 style={{
              backgroundColor: 'rgb(17, 19, 66)',
              padding: '14px',
              fontWeight: '700',
              fontSize: '25px',
              textTransform: 'uppercase',
              marginTop: '20px',
              marginBottom: '20px',
              color: '#ffffff',
              textAlign: 'center',
            }}>
              Announcement
            </h5>
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark" style={{
                  backgroundColor:'rgb(17, 19, 66)',
                  color:'#ffffff',
                }}>
                  <tr>
                    <th style={{
                      width:'10%',
                    }}>Sr.No.</th>
                    <th style={{
                      width:'90%',
                    }}>Tittle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td>1</td>
                      <td>
                      <a style={{
                          color:'#0000ff',
                        }} 
                        href="https://doca.gov.in/goc/assets/document/Final-Results-Stage-1.pdf" target="_blank">
                        <strong>Onion Grand Challenge:</strong> Result announcement (Stage - I)&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faFilePdf} />
                      </a>
                      </td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>
                      <a style={{
                        color:'#0000ff',
                      }} 
                      href="javascript:void(0)">
                      <strong>Onion Grand Challenge:</strong> Result announcement (Stage - II)&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faFilePdf} />
                      </a>
                      </td>
                  </tr>
                  <tr>
                      <td>3</td>
                      <td>
                      <a style={{
                        color:'#0000ff',
                      }}
                      href="javascript:void(0)">
                      <strong>Tomato Grand Challenge:</strong> Deadline for Idea Submission is <span class="blink">September 30th, 2023</span>
                      </a>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Announcement;
