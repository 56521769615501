import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModalBody,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import "react-toastify/dist/ReactToastify.css";

export default function Evaluate(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [evaluation, setEvaluation] = useState(props?.evaluation ?? {});

  function handleSubmit(e) {
    e.preventDefault();

    let data = new FormData(e.target);

    toast.promise(
      axios().post(`/evaluator/${props.evaluation.id}/update`, data),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {
            setIsSubmitting(false);
            props.setFetch(true);
            props.setCurrent(false);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCurrent(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-left text-md-center mb-4 mt-2">
              Evaluate Submission: {evaluation.submission.id}
            </p>
            <form onSubmit={handleSubmit}>
              <h6 class="mb-2 pb-1 fw-bold">
                1. Innovation/Novelty in approach
                <span className="redColor">
                  (Maximum score 0-10) (Weightage: 20):{" "}
                </span>
              </h6>
              <MDBInput
                onChange={(e) =>
                  e.target.value > 10 ? (e.target.value = 10) : ""
                }
                required={true}
                group
                type="number"
                min={0}
                max={10}
                step={1}
                validate
                error="wrong"
                success="right"
                name={"novelty"}
                defaultValue={evaluation.novelty}
              />
              <br></br>
              <h6 class="mb-2 pb-1">
                2. Technology
                <span className="redColor">
                  (Maximum score 0-10) (Weightage: 15):{" "}
                </span>
              </h6>
              <MDBInput
                required={true}
                onChange={(e) =>
                  e.target.value > 10 ? (e.target.value = 10) : ""
                }
                group
                type="number"
                min={0}
                max={10}
                step={1}
                validate
                error="wrong"
                success="right"
                name={"technology"}
                defaultValue={evaluation.technology}
              />
              <br />
              <h6 className="mb-2 pb-1">
                3. Minimal Viable Product/Prototype/Demo
                <span className="redColor">
                  (Maximum score 0-10) (Weightage: 15):{" "}
                </span>{" "}
              </h6>
              <MDBInput
                required={true}
                onChange={(e) =>
                  e.target.value > 10 ? (e.target.value = 10) : ""
                }
                group
                type="number"
                min={0}
                max={10}
                step={1}
                validate
                error="wrong"
                success="right"
                name={"demo"}
                defaultValue={evaluation.demo}
              />
              <br />
              <h6 className="mb-2 pb-1">
                4. Criticality/Impact
                <span className="redColor">
                  (Maximum score 0-10) (Weightage: 25):{" "}
                </span>{" "}
              </h6>
              <MDBInput
                required={true}
                onChange={(e) =>
                  e.target.value > 10 ? (e.target.value = 10) : ""
                }
                group
                type="number"
                min={0}
                max={10}
                step={1}
                validate
                error="wrong"
                success="right"
                name={"impact"}
                defaultValue={evaluation.impact}
              />
              <br />
              <h6 className="mb-2 pb-1">
                5. Commercial viability and Cost Effectiveness
                <span className="redColor">
                  (Maximum score 0-10) (Weightage: 25):{" "}
                </span>{" "}
              </h6>
              <MDBInput
                required={true}
                onChange={(e) =>
                  e.target.value > 10 ? (e.target.value = 10) : ""
                }
                group
                type="number"
                min={0}
                max={10}
                step={1}
                validate
                error="wrong"
                success="right"
                name={"cost_effectiveness"}
                defaultValue={evaluation.cost_effectiveness}
              />
              <br />
              <h6 className="mb-2 pb-1">6. Remarks:</h6>
              <textarea
                required={true}
                rows={4}
                className={"form form-control"}
                name={"remarks"}
                defaultValue={evaluation.remarks}
              />
              <br />

              <h6 className="mb-2 pb-1">7. Recommend:</h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={`recommend`}
                  defaultValue={evaluation.recommend}
                >
                  <option value="">Please select an option</option>
                  <option value="rejected">Rejected</option>
                  <option value="borderline">Borderline</option>
                  <option value="accepted">Accepted</option>
                  <option value="strongly_accepted">Strongly Accepted</option>
                </select>
              </div>
              <br />
              <div className="text-center">
                <MDBBtn color="primary" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MDBModalBody>
  );
}
