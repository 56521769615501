import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Evaluate from "../../components/Evaluator/Evaluate";
import Loading from "../../components/Loading";
import { useLocation } from "react-router-dom";

export default function Evaluations(props) {
  const [evaluations, setEvaluations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetch, setFetch] = useState(true);

  const [current, setCurrent] = useState(false);

  const search = useLocation().search;
  const ps = new URLSearchParams(search).get("ps");

  let count = 0;
  const [onlyEvaluated, setOnlyEvaluated] = useState(false);

  useEffect(() => {
    if (fetch) {
      setIsLoading(true);
      axios()
        .get("/evaluator/evaluations")
        .then((response) => {
          response.data.data = response.data.data.filter((evaluation) => {
            let r = true;
            if (ps !== undefined && ps != null) {
              r =
                r &&
                evaluation.submission.problem_statement.id.toString() === ps;
            }

            if (onlyEvaluated === "evaluated") {
              r = r && evaluation.last_evaluated_at !== null;
            } else if (onlyEvaluated === "not_evaluated") {
              r = r && evaluation.last_evaluated_at === null;
            }
            return r;
          });

          setEvaluations(response.data.data);
          setIsLoading(false);
          setFetch(false);
        });
    }
  }, [fetch, onlyEvaluated]);

  function calculate_score(evaluation) {
    let score = 0;

    score += (evaluation.novelty * 20) / 10;
    score += (evaluation.technology * 15) / 10;
    score += (evaluation.demo * 15) / 10;
    score += (evaluation.impact * 25) / 10;
    score += (evaluation.cost_effectiveness * 25) / 10;

    return "Scored " + score.toFixed(0).toString();
  }

  if (isLoading) {
    return <Loading />;
  }

  if (current !== false) {
    return (
      <Evaluate
        setCurrent={setCurrent}
        evaluation={evaluations[current]}
        setFetch={setFetch}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <div className="row mb-2">
        <h2 className="mx-3 col-md">Evaluate</h2>
        <MDBBtn
          onClick={() => {
            setOnlyEvaluated(
              onlyEvaluated === false
                ? "evaluated"
                : onlyEvaluated === "evaluated"
                ? "not_evaluated"
                : false
            );
            setFetch(true);
          }}
          color="primary"
          className="col-10 col-md-2 mr-4 evalButton"
        >
          {onlyEvaluated === false
            ? "Show only evaluated"
            : onlyEvaluated === "evaluated"
            ? "Show not evaluated"
            : "Show all"}
        </MDBBtn>
      </div>
      <MDBTable hover striped bordered>
        <MDBTableHead color="primary-color" textWhite>
          <tr>
            <th>Sr. no</th>
            <th>Submission & Team ID</th>
            <th>Vertical Title</th>
            <th>Idea title</th>
            <th>Description</th>
            <th>Abstract</th>
            <th>Video</th>
            <th>Idea/Other Document</th>
            <th>Action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {evaluations.map((evaluation, index) => (
            <tr key={index}>
              <td>{(count += 1)}</td>
              <td>
                Submission ID: {evaluation.idea_submission_id} <br />
                Team ID: {evaluation.submission.team_id}
              </td>
              <td>{evaluation.submission.problem_statement.title}</td>
              <td>{evaluation.submission.title}</td>
              <td
                onDoubleClick={() => alert(evaluation.submission.description)}
              >
                {evaluation.submission.description.substring(0, 150)}
              </td>
              <td onDoubleClick={() => alert(evaluation.submission.abstract)}>
                {evaluation.submission.abstract.substring(0, 150)}
              </td>
              <td>
                {evaluation.submission.video_url ? (
                  <MDBBtn
                    color="primary"
                    className="col p-2"
                    href={evaluation.submission.video_url}
                  >
                    View
                  </MDBBtn>
                ) : (
                  "Not Submitted"
                )}
              </td>

              <td>
                <div style={{ display: "block", marginBottom: "10px" }}>
                  {evaluation.submission.idea_document_file ? (
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      href={evaluation.submission.idea_document_file}
                    >
                      View Idea Document
                    </MDBBtn>
                  ) : (
                    "Not Submitted"
                  )}
                </div>
                <div style={{ display: "block", marginBottom: "10px" }}>
                  {evaluation.submission.other_document_file ? (
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      href={evaluation.submission.other_document_file}
                    >
                      View Other Document
                    </MDBBtn>
                  ) : (
                    "Not Submitted"
                  )}
                </div>
                <div style={{ display: "block" }}>
                  {evaluation.submission.stage_2_file ? (
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      href={evaluation.submission.stage_2_file}
                    >
                      View Stage 2 Document
                    </MDBBtn>
                  ) : (
                    "Not Submitted"
                  )}
                </div>
              </td>
              <td>
                <MDBBtn
                  color={evaluation.last_evaluated_at ? "warning" : "primary"}
                  className="col p-2"
                  onClick={() => setCurrent(index)}
                >
                  {evaluation.last_evaluated_at
                    ? calculate_score(evaluation)
                    : "Evaluate"}
                </MDBBtn>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
}
