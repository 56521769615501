import React, { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import Loading from "../../../components/Loading";
import IdeaSubmission from "../../../components/IdeaSubmission";
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

export const Submissions = (props) => {
  const [user, setUser] = useState(props.user);
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState("");

  const [currentSub, setCurrentSub] = useState(false);

  useEffect(() => {
    axios()
      .get("/submissions")
      .then((response) => {
        setSubmissions(response.data.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (currentSub !== false) {
    return (
      <IdeaSubmission
        setCurrentPs={setCurrentSub}
        problem={submissions[currentSub].problem_statement}
        setFetchProblems={(a) => a}
        idea={submissions[currentSub]}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <h2
        style={{
          fontSize: "1.50rem",
          padding: "15px 0px",
          backgroundColor: "rgb(39, 44, 147)",
          color: "#ffffff",
          marginBottom: "15px",
        }}
        className="text-center"
      >
        Your Submissions in all Grand Challenges
      </h2>
      <input
        style={{
          marginBottom: "15px",
          float: "right",
          border: "2px solid #642eab",
          padding: "5px",
          borderRadius: "5px",
        }}
        type={"text"}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={"Search"}
      />
      <div className="">
        {submissions.length ? (
          <MDBTable hover striped bordered>
            <MDBTableHead color="primary" textWhite>
              <tr className="bg-warning">
                <th>S.No.</th>
                <th>Submission ID</th>
                <th>Challenge</th>
                <th>Vertical Title</th>
                <th>Idea Title</th>
                <th>Documents</th>
                <th>Status</th>
                <th>Submitted At</th>

                {/*<th>Action</th>*/}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {submissions
                .filter((submission) => {
                  if (search === "") {
                    return true;
                  }

                  return (
                    submission.title
                      .toLowerCase()
                      .search(search.toLowerCase()) !== -1 ||
                    submission.challenge
                      .toLowerCase()
                      .search(search.toLowerCase()) !== -1
                  );
                })
                .map((submission, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{submission.id}</td>
                    <td>
                      <span>
                        {submission.challenge.toUpperCase()} Challenge
                      </span>
                    </td>
                    <td>{submission.problem_statement.title}</td>

                    <td>{submission.title}</td>
                    <td>
                      {submission.idea_document_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2"
                          href={submission.idea_document_file}
                        >
                          View Idea Document
                        </MDBBtn>
                      ) : (
                        ""
                      )}
                      {submission.other_document_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2 mt-2"
                          href={submission.other_document_file}
                        >
                          View Other Document
                        </MDBBtn>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <span>{submission.status}</span>
                    </td>
                    <td>{submission.created_at}</td>

                    {/*<td>*/}
                    {/*  <MDBBtn*/}
                    {/*    color={"warning"}*/}
                    {/*    className="col p-2"*/}
                    {/*    // TODO Remove when enabling again*/}
                    {/*    // onClick={() => setCurrentSub(index)}*/}
                    {/*    disabled={true}*/}
                    {/*  >*/}
                    {/*    Edit Submission*/}
                    {/*  </MDBBtn>*/}
                    {/*</td>*/}
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <h4 className={"text-center text-warning mb-4"}>
            No submissions yet!
          </h4>
        )}
      </div>
    </div>
  );
};
