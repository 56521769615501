import React, { useRef } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModalBody,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import RouteHelper from "../../../utils/routeHelper";

export default function Msme(props) {
  const previous = props.previous?.category === "msme" ? props.previous : false;

  const [startupRegisteredIndia, setStartupRegisteredIndia] = useState(
    previous ? previous?.startup_registered_under_india : false
  );
  const [startupRole, setStartupRole] = useState(
    previous ? previous?.role_in_startup_company_msme : false
  );

  const [isLoading, setIsLoading] = useState(false);

  function handleFormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("category", "msme");
    toast.promise(
      axios().post(`/challenges/register/${props.track.id}`, data),
      {
        pending: {
          render() {
            setIsLoading(true);
            return "Registering......";
          },
        },
        success: {
          render() {
            setIsLoading(false);
            RouteHelper.redirect("/dashboard");
            return "Team registered successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsLoading(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                value.forEach((v) =>
                  toast.error(v.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                );
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCategory(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4 mt-4">
              {props.track.name} Registration
            </p>
            <form onSubmit={handleFormSubmit}>
              <h6 className="mb-2 pb-1 fw-bold">Team Name: </h6>
              <MDBInput
                required={true}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name="name"
                defaultValue={previous ? previous?.name : ""}
              />
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Year of establishment </h6>
              <MDBInput
                group
                type="number"
                min={2000}
                max={2023} //TODO: Update max year
                validate
                error="wrong"
                success="right"
                name={"startup_company_msme_founded_year"}
                required={true}
                defaultValue={
                  previous ? previous?.startup_company_msme_founded_year : ""
                }
              />
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">Name of the Business Entity: </h6>
              <MDBInput
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"startup_company_msme_name"}
                required={true}
                defaultValue={
                  previous ? previous?.startup_company_msme_name : ""
                }
              />
              <br></br>
              <h6 className="mb-2 pb-1 fw-bold">Business Type:</h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"startup_company_msme_business_type"}
                  defaultValue={
                    previous ? previous?.startup_company_msme_business_type : ""
                  }
                >
                  <option value="manufacturing">Manufacturing</option>
                  <option value="service">Service</option>
                  <option value="both">Both</option>
                </select>
              </div>
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Udyam Registration No:</h6>
              <MDBInput
                group
                type="text"
                defaultValue={
                  previous ? previous?.msme_registration_number : ""
                }
                validate
                error="wrong"
                success="right"
                name={"msme_registration_number"}
                required={true}
              />
              <br></br>
              <h6 className="mb-2 pb-1 fw-bold">
                Upload Registration Certificate (File Type: PDF, Max Size:10MB)
                {/*{previous?.msme_registration_certificate_file*/}
                {/*  ? "(Leave empty to use previous certificate file)"*/}
                {/*  : ""}*/}:
              </h6>
              <MDBInput
                group
                type="file"
                validate
                error="wrong"
                required={true}
                success="right"
                name={"msme_registration_certificate_file"}
                accept={"application/pdf"}
              />
              <br></br>
              <h6 class="mb-2 pb-1 fw-bold">Address: </h6>
              <MDBTextArea
                rows={4}
                group
                type="textarea"
                validate
                error="wrong"
                success="right"
                name={"company_msme_address"}
                required={true}
                defaultValue={previous ? previous?.company_msme_address : ""}
              />
              <br></br>
              <h6 className="mb-2 pb-1 fw-bold">State:</h6>
              <MDBInput
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"company_msme_state"}
                required={true}
                defaultValue={previous ? previous?.company_msme_state : ""}
              />
              <br></br>
              <h6 className="mb-2 pb-1 fw-bold">District:</h6>
              <MDBInput
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name={"company_msme_district"}
                required={true}
                defaultValue={previous ? previous?.company_msme_district : ""}
              />
              <br></br>
              <h6 className="mb-2 pb-1 fw-bold">Pincode:</h6>
              <MDBInput
                group
                type="number"
                minLength={6}
                maxLength={6}
                validate
                error="wrong"
                success="right"
                name={"company_msme_pincode"}
                required={true}
                defaultValue={previous ? previous?.company_msme_pincode : ""}
              />
              <br></br>

              <h6 class="mb-2 pb-1 fw-bold">
                Is business entity registered under Startup India?
              </h6>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="startup_registered_under_india"
                  id="startup_registered_under_india_yes"
                  value={1}
                  required={true}
                  defaultChecked={startupRegisteredIndia}
                  onChange={() => setStartupRegisteredIndia(true)}
                />
                <label
                  class="form-check-label"
                  htmlFor="startup_registered_under_india_yes"
                >
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="startup_registered_under_india"
                  id="startup_registered_under_india_no"
                  value={0}
                  required={true}
                  defaultChecked={!startupRegisteredIndia}
                  onChange={() => setStartupRegisteredIndia(false)}
                />
                <label
                  class="form-check-label"
                  htmlFor="startup_registered_under_india_no"
                >
                  No
                </label>
              </div>

              <br></br>
              <br></br>
              {startupRegisteredIndia && (
                <div>
                  <h6 class="mb-2 pb-1 fw-bold">
                    Upload Your Startup India Registration Certificate (File
                    Type: PDF, Max Size:10MB)
                    {/*{previous?.startup_india_registration_certificate_file*/}
                    {/*  ? "(Leave empty to use previous certificate file)"*/}
                    {/*  : ""}*/}:
                  </h6>
                  <MDBInput
                    group
                    type="file"
                    validate
                    error="wrong"
                    success="right"
                    name={"startup_india_registration_certificate_file"}
                    accept={"application/pdf"}
                    required={true}
                  />
                  <br></br>
                </div>
              )}

              <br />
              <h4>Team Leader:</h4>
              <h6 className="mb-2 pb-1 fw-bold">Education </h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"education_info"}
                  defaultValue={
                    previous ? previous?.members[0]?.education_info : ""
                  }
                >
                  <option value="Diploma">Diploma</option>
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                  <option value="Ph.D">PhD</option>
                </select>
              </div>
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Your Role in the company:</h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"role_in_startup_company_msme"}
                  defaultValue={
                    previous
                      ? previous?.members[0]?.role_in_startup_company_msme
                      : ""
                  }
                  onChange={(e) => setStartupRole(e.target.value)}
                >
                  <option value="Director">Director</option>
                  <option value="CEO">CEO</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <br />
              {startupRole &&
                startupRole !== "CEO" &&
                startupRole !== "Director" && (
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Specify role in startup:{" "}
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      name="role_in_startup_company_msme"
                      defaultValue={
                        previous
                          ? previous?.members[0]?.role_in_startup_company_msme
                          : ""
                      }
                    />
                    <br />
                  </div>
                )}
              <div className="text-center">
                <MDBBtn color="primary" disabled={isLoading}>
                  {isLoading ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBModalBody>
  );
}
