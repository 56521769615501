import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Auth/Login";
import EmailVerify from "./pages/Auth/EmailVerify";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register from "./pages/Auth/Register";
import Header from "./components/Header/Header";
import { useEffect, useState } from "react";
import EvaluatorRegistration from "./pages/Evaluator/EvaluatorRegistration";
import TeamBase from "./pages/Team/Base";
import SpocBase from "./pages/Spoc/Base";
import EvaluatorBase from "./pages/Evaluator/Base";
import Staging from "./pages/Team/Challenges/Staging";
import Landing from "./pages/Landing";
import SpocChallenges from "./pages/Spoc/Challenges";
// import AdminBase from "./pages/Admin/Base";
import ChallengesBase from "./pages/Team/Challenges/ChalllengesBase";
import {
  MDBContainer,
  MDBContainerFluid,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";

function App() {
  const [sideBarOpen, setSideBarOpen] = useState(true);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/develop" element={<Develop />} /> */}
        <Route path="/challenges/*" element={<ChallengesBase />}></Route>
        <Route path="/spoc/challenges" element={<SpocChallenges />} />

        <Route path="/spoc/*" element={<SpocBase />} />
        <Route path="/team/*" element={<TeamBase />} />
        {/*<Route path="/admin/*" element={<AdminBase />} />*/}
        <Route path="/evaluator/*" element={<EvaluatorBase />} />
        <Route
          path="/*"
          element={
            <div>
              <Header isSideBarOpen={sideBarOpen} isSideBarPresent={false} />
              <div
                style={{
                  minHeight: "70.4vh",
                  backgroundImage: 'url("./er_final.png")',
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: "72.5vh",
                  position: "relative",
                }}
              >
                <Routes>
                  {/*AUTH ROUTES*/}

                  {/* EvaluatorRegistration private link */}
                  <Route
                    path="/evaluators/register"
                    element={<EvaluatorRegistration />}
                  />
                  <Route path="/" element={<Navigate to={"/login"} />} />
                  <Route path="/landing" element={<Landing />} />
                  <Route path="/stages" element={<Staging />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/verify" element={<EmailVerify />} />
                  <Route path="/reset" element={<ResetPassword />} />
                  <Route path="/reset/:token" element={<ResetPassword />} />

                  <Route
                    path="/dashboard"
                    element={<Navigate to={"/team"} />}
                  />
                  {/*<Route path="/team/manage" element={<ManageTeam />} />*/}

                  {/*TEAM REGISTER ROUTE*/}
                </Routes>
                <MDBContainer
                  fluid
                  style={{
                    backgroundColor: "#2d0e54",
                  }}
                >
                  <MDBRow className="row d-flex justify-content-center mt-4">
                    <MDBCol md="2" className="text-center">
                      &nbsp;
                    </MDBCol>
                    <MDBCol md="4" className="text-center">
                      <div className="footerBox">
                        <h4>Important Links</h4>
                        <a href="https://doca.gov.in/goc/" target="_blank">
                          Onion Grand Challenge
                        </a>
                        <a href="https://doca.gov.in/gtc/" target="_blank">
                          Tomato Grand Challenge
                        </a>
                        <a href="https://mic.gov.in/" target="_blank">
                          MoE’s Innovation Cell{" "}
                        </a>
                        <a href="https://doca.gov.in/" target="_blank">
                          Department of consumer affairs
                        </a>
                      </div>
                    </MDBCol>
                    <MDBCol md="1" className="text-center">
                      &nbsp;
                    </MDBCol>
                    <MDBCol md="3" className="">
                      <div className="footerBoxRight">
                        <h4>Contact Us</h4>
                        <ul className="list-unstyled">
                          <li>
                            <i
                              className="fa fa-envelope-open"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;For Onion:{" "}
                            <a
                              href="mailto:gc.onion@aicte-india.org"
                              target="_blank"
                            >
                              gc.onion@aicte-india.org
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa-envelope-open"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;For Tomato:{" "}
                            <a
                              href="mailto:tgc@aicte-india.org"
                              target="_blank"
                            >
                              tgc@aicte-india.org
                            </a>
                          </li>

                          <li>
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            &nbsp;&nbsp;Phone:{" "}
                            <a href="tel:011 2958 1509" target="_blank">
                              011 2958 1509
                            </a>
                          </li>
                        </ul>
                      </div>
                    </MDBCol>
                    <MDBCol md="2" className="text-center">
                      &nbsp;
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
