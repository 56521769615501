import React from "react";
import {
  FaUsers,
  FaUser,
  FaUserTie,
  FaLightbulb,
  FaPhone,
  FaUserCircle,
  FaChessKing,
  FaBell,
} from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { BsPatchQuestionFill } from "react-icons/bs";

export const SidebarData = [
  {
    title: "Grand Challenges",
    path: "/challenges",
    icon: <FaChessKing />,

    // iconClosed: <RiArrowDownSFill />,
    // iconOpened: <RiArrowUpSFill />,
  },
  {
    title: "Submit Idea",
    path: "/challenges/submit",
    icon: <FaLightbulb />,
    // iconClosed: <RiArrowDownSFill />,
    // iconOpened: <RiArrowUpSFill />,
  },
  {
    title: "View Submissions",
    path: "/challenges/submissions",
    icon: <HiLightBulb />,
    // iconClosed: <RiArrowDownSFill />,
    // iconOpened: <RiArrowUpSFill />,
  },
 
  {
    title: "View Teams",
    path: "/challenges/teams",
    icon: <FaUsers />,
    // iconClosed: <RiArrowDownSFill />,
    // iconOpened: <RiArrowUpSFill />,
  },
  {
    title: "Watch Videos",
    path: "/challenges/videos",
    icon: <HiLightBulb />,
    // iconClosed: <RiArrowDownSFill />,
    // iconOpened: <RiArrowUpSFill />,
  },
  {
    title: "Announcement",
    path: "/challenges/announcement",
    icon: <FaBell />,

  },
  {
    title: "Contact Us",
    path: "/challenges/contact",
    icon: <FaPhone />,
  },

  // {
  //   title: "Profile",
  //   path: "profile",
  //   icon: <FaUserCircle />,
  // },
];
