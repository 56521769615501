import React from "react";
import RouteHelper from "../../../utils/routeHelper";
import { store } from "../../../utils/store";
const Staging = ({ user }) => {
  const selected = user.is_round_1_selected;
  const challenge = user.current_challenge;

  return (
    <div>
      <div className="container stages" id="stag">
        <div className="row">
          <div className="col-md-11 offset-md-1">
            <div className="row">
              <div className="col-md-4">
                <div className="card  m-4 bg-dark text-center">
                  <div className="card-body text-white p-3">
                    <h5 className="card-title text-center mb-4 stage1">
                      Stage-1
                    </h5>
                    <h5 className="card-title text-center">
                      Ideation to POC Stage
                    </h5>
                    <p className="card-text text-center">
                      Prize money of Rs.25000 up to 40 teams. Development Cost
                      up to Rs.75000 per team
                    </p>
                    <h5 className="">Duration: 3 to 4 Months</h5>
                    <div
                      className="bg-warning py-1 mt-7"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Status:{" "}
                      {challenge.stage === 1
                        ? "Submission Open"
                        : "Closed for Submissions"}
                    </div>
                    <button
                      onClick={() => {
                        localStorage.setItem("stage", 1);
                        RouteHelper.redirect(
                          "/team/idea-submission/problem-statements"
                        );
                      }}
                      className="btn btn-primary mt-4 w-full w-100"
                      style={{
                        backgroundColor: "#f93154",
                      }}
                    >
                      {user.submitted_idea ? "View Submissions" : "Submit Idea"}
                    </button>
                    {challenge.stage > 1 && (
                      <div
                        className={
                          selected
                            ? "bg-success py-1 mt-4"
                            : "bg-danger py-1 mt-4"
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {selected
                          ? "Your idea is Selected in Stage 1"
                          : "Your ideas are Not Selected in Stage 1"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card  m-4 bg-dark text-center">
                  <div className="card-body text-white p-3">
                    <h5 className="card-title text-center mb-4 stage2">
                      Stage-2
                    </h5>
                    <h5 className="card-title text-center">
                      POC to Product Development Stage
                    </h5>
                    <p className="card-text text-center">
                      Prize money of Rs.50000 up to 20 teams. Development Cost
                      up to Rs.5 Lakhs per team
                    </p>
                    <h5>Duration: 3 to 4 Months</h5>
                    <div
                      className="bg-warning py-1 mt-7"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Status:{" "}
                      {challenge.stage === 2
                        ? "Submissions Open"
                        : challenge.stage === 1
                        ? "Activated later"
                        : "Closed for Submissions"}
                    </div>
                    {challenge.stage > 1 && selected && (
                      <>
                        <button
                          onClick={() => {
                            store("stage", 1);
                            RouteHelper.redirect("/team/submissions/stage2");
                          }}
                          className="btn btn-primary mt-4 w-full w-100"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f93154",
                          }}
                        >
                          View Submission
                        </button>
                        <div className="bg-success py-1 mt-4">
                          Selected Submission: {user.selected_submission.title}
                        </div>
                        {/*<p className="text-center mb-4 text-danger mt-3">
                      Deadline for Submission is March 2<sup>nd</sup> 2023
                    </p>*/}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card  m-4 bg-dark text-center">
                  <div className="card-body text-white p-3 stage3">
                    <h5 className="card-title text-center mb-4 stage3">
                      Stage-3
                    </h5>
                    <h5 className="card-title text-center">
                      Field Implementation
                    </h5>
                    <p className="card-text text-center">
                      One winner in each vertical and two/four runner-ups will
                      be selected and supported appropriately for the field
                      deployment of the solution
                    </p>
                    <h5>Duration: 3 to 4 Months</h5>
                    <div
                      className="bg-warning py-1 mt-7"
                      style={{
                        display: "flex",
                        alignItems: "flexEnd",
                        justifyContent: "center",
                      }}
                    >
                      Status:{" "}
                      {challenge.stage === 3
                        ? "Submissions Open"
                        : challenge.stage < 3
                        ? "Activated later"
                        : "Closed for Submissions"}
                    </div>

                    {challenge.stage > 2 && selected && (
                      <>
                        <button
                          onClick={() => {
                            store("stage", 1);
                            RouteHelper.redirect("/team/submissions/stage2");
                          }}
                          className="btn btn-primary mt-4 w-100"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f93154",
                          }}
                        >
                          View Submission
                        </button>
                        <div className="bg-success py-1 mt-4">
                          Selected Submission: {user.selected_submission.title}
                        </div>
                        {/*<p className="text-center mb-4 text-danger mt-3">
                      Deadline for Submission is March 2<sup>nd</sup> 2023
                    </p>*/}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staging;
