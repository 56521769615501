import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { MDBBtn } from "mdb-react-ui-kit";

export default function Dashboard(props) {
  const [problems, setProblems] = useState([]);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    axios()
      .get("/evaluator/problems")
      .then((res) => {
        setProblems(res.data);
        setIsInitializing(false);
      });
  }, []);

  if (isInitializing) {
    return <Loading />;
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Verticals</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "#111342",
                      color: "#ffffff",
                    }}
                  >
                    <th scope="col">Sr.NO</th>
                    <th scope="col">Vertical Title</th>
                    <th scope="col">No of ideas allocated</th>
                    <th scope="col">No of ideas evaluated</th>
                    <th scope="col">No of ideas yet to evaluate</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {problems.map((problem, index) => {
                    return (
                      <tr key={index} style={{ textAlign: "left" }}>
                        <th scope="row">{index + 1}</th>
                        <td>{problem.title}</td>
                        <td>{problem.allocated}</td>
                        <td>{problem.evaluated}</td>
                        <td>{problem.pending}</td>
                        <td>
                          <MDBBtn
                            href={"/evaluator/evaluate?ps=" + problem.id}
                            color="primary"
                          >
                            View
                          </MDBBtn>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
