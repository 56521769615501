import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import IdeaSubmission from "../../components/IdeaSubmission";
import Loading from "../../components/Loading";
import { retrieve } from "../../utils/store";

export const ProblemStatements = (props) => {
  const [user, setUser] = useState(props.user);
  const [problems, setProblems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchProblems, setFetchProblems] = useState(true);

  const [currentPs, setCurrentPs] = useState(false);

  useEffect(() => {
    axios()
      .get("/problems")
      .then((response) => {
        setProblems(response.data.data);
        setIsLoading(false);
        setFetchProblems(false);
      });
  }, [fetchProblems]);

  if (isLoading) {
    return <Loading />;
  }

  if (currentPs !== false) {
    return (
      <IdeaSubmission
        setCurrentPs={setCurrentPs}
        problem={problems[currentPs]}
        setFetchProblems={setFetchProblems}
        disabled={
          user.current_challenge.stage !== 1 ||
          !user.current_challenge.submission_open
        }
        addSubmission={false}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <h2
        style={{
          fontSize: "1.50rem",
          padding: "15px 0px",
          backgroundColor: "rgb(39, 44, 147)",
          color: "#ffffff",
          marginBottom: "15px",
        }}
        className="text-center"
      >
        Verticals
      </h2>
      {user.current_challenge.stage === 1 && (
        <div className={"d-flex justify-content-center text-white"}>
          <span className={"bg-danger text-center px-3 py-1 mb-2"}>
            Last date of Idea submission is 15th September 2023
          </span>
        </div>
      )}
      <MDBTable hover striped bordered>
        <MDBTableHead color="primary-color" textWhite>
          <tr className="bg-warning">
            <th>S.no</th>
            <th>Vertical Title</th>
            <th>Vertical Description</th>
            <th>More Details</th>
            <th>Stage1: Idea submission</th>
            <th>Stage2: PoC to Product Development Submission</th>
            <th>Stage 3: Field Implementation Submission</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {problems.map((problem, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{problem.title}</td>
              <td>{problem.description}</td>
              <td>
                {problem.guideline_file && (
                  <div className={"row my-1"}>
                    <MDBBtn
                      color="secondary"
                      target="_blank"
                      href={problem.guideline_file}
                    >
                      Guidelines
                    </MDBBtn>
                  </div>
                )}
                {problem.datasheet_file && (
                  <div className={"row my-1"}>
                    <MDBBtn
                      color="secondary"
                      target="_blank"
                      href={problem.datasheet_file}
                    >
                      Datasheet
                    </MDBBtn>
                  </div>
                )}
                {problem.video_url && (
                  <div className={"row my-1"}>
                    <MDBBtn
                      color="secondary"
                      target="_blank"
                      href={problem.video_url}
                    >
                      View Video
                    </MDBBtn>
                  </div>
                )}
              </td>
              <td>
                <MDBBtn
                  color={
                    problem.is_submitted
                      ? user.current_challenge.stage === 1
                        ? "info"
                        : "info"
                      : user.current_challenge.stage === 1 &&
                        user.current_challenge.submissions_open
                      ? "info"
                      : "danger"
                  }
                  className="col p-2"
                  onClick={() => setCurrentPs(index)}
                  disabled={
                    (user.current_challenge.stage !== 1 ||
                      user.current_challenge.submissions_open) &&
                    !problem.is_submitted
                  }
                >
                  {problem.is_submitted
                    ? user.current_challenge.stage === 1 &&
                      user.current_challenge.submissions_open
                      ? "Submit Idea"
                      : "View Idea"
                    : user.current_challenge.stage === 1 &&
                      user.current_challenge.submissions_open
                    ? "Submit Idea"
                    : "Closed"}
                </MDBBtn>
              </td>
              <td>
                <MDBBtn
                  color={
                    user.current_challenge.stage === 1
                      ? "dark"
                      : problem.submission_status === "Round 1 Selected"
                      ? "info"
                      : "danger"
                  }
                  className="col p-2"
                  disabled={
                    user.current_challenge.stage < 2 ||
                    problem.submission_status !== "Round 1 Selected"
                  }
                  href={
                    user.current_challenge.stage < 2 ||
                    problem.submission_status !== "Round 1 Selected"
                      ? false
                      : "/team/submissions/stage2"
                  }
                >
                  {user.current_challenge.stage === 1
                    ? "Activated at a later time"
                    : problem.submission_status === "Round 1 Selected"
                    ? "View"
                    : "Not Selected in Stage 2"}
                </MDBBtn>
              </td>
              <td>
                <MDBBtn
                  color={
                    user.current_challenge.stage < 3
                      ? "dark"
                      : problem.submission_status === "Round 1 Selected"
                      ? "info"
                      : "danger"
                  }
                  className="col p-2"
                  disabled={
                    user.current_challenge.stage < 3 ||
                    problem.submission_status !== "Round 2 Selected"
                  }
                  href={
                    user.current_challenge.stage < 3 ||
                    problem.submission_status !== "Round 2 Selected"
                      ? false
                      : "/team/submissions/stage3"
                  }
                >
                  {user.current_challenge.stage < 3
                    ? "Activated at a later time"
                    : problem.submission_status === "Round 2 Selected"
                    ? "View"
                    : "Not Selected in Stage 2"}
                </MDBBtn>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export const SubmittedIdeas = (props) => {
  const [user, setUser] = useState(props.user);
  const [submissions, setSubmissions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchProblems, setFetchProblems] = useState(true);

  const [currentSub, setCurrentSub] = useState(false);

  useEffect(() => {
    if (fetchProblems === true) {
      axios()
        .get("/submissions")
        .then((response) => {
          setSubmissions(response.data.data);
          setIsLoading(false);
          setFetchProblems(false);
        });
    }
  }, [fetchProblems]);

  if (isLoading) {
    return <Loading />;
  }

  if (currentSub !== false) {
    return (
      <IdeaSubmission
        setCurrentPs={setCurrentSub}
        problem={submissions[currentSub].problem_statement}
        setFetchProblems={setFetchProblems}
        idea={submissions[currentSub]}
        disabled={user.current_challenge.stage !== 1}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <h2
        style={{
          fontSize: "1.50rem",
          padding: "15px 0px",
          backgroundColor: "rgb(39, 44, 147)",
          color: "#ffffff",
          marginBottom: "15px",
        }}
        className="text-center"
      >
        Your submission for Challenge Name
      </h2>
      <div className="">
        {submissions.length ? (
          <MDBTable hover striped bordered>
            <MDBTableHead color="primary" textWhite>
              <tr className="bg-warning">
                <th>Submission ID</th>
                <th>Idea Title</th>
                <th>Idea Document</th>
                <th>Other Document</th>
                <th>Submitted At</th>
                {/*<th>Vertical Title</th>*/}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {submissions.map((submission, index) => (
                <tr key={index}>
                  <td>{submission.id}</td>
                  <td>{submission.title}</td>
                  <td>
                    {submission.idea_document_file ? (
                      <MDBBtn
                        color="primary"
                        className="col p-2"
                        href={submission.idea_document_file}
                      >
                        View Idea Document
                      </MDBBtn>
                    ) : (
                      "Not Submitted"
                    )}
                  </td>
                  <td>
                    {submission.other_document_file ? (
                      <MDBBtn
                        color="primary"
                        className="col p-2"
                        href={submission.other_document_file}
                      >
                        View Other Document
                      </MDBBtn>
                    ) : (
                      "Not Submitted"
                    )}
                  </td>
                  <td>{submission.created_at}</td>
                  {/*<td>{submission.problem_statement.title}</td>*/}
                  <td>
                    <span>{submission.status}</span>
                  </td>
                  <td>
                    <MDBBtn
                      color={
                        user.current_challenge.stage === 1
                          ? "warning"
                          : "primary"
                      }
                      className="col p-2"
                      onClick={(e) => setCurrentSub(index)}
                    >
                      {user.current_challenge.stage === 1 &&
                      user.current_challenge.submission_open
                        ? "Edit Submission"
                        : "View Submission"}
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <h4 className={"text-center text-warning mb-4"}>
            No submissions yet!
          </h4>
        )}
      </div>
    </div>
  );
};
