import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn ,  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import StudentFaculty from "../../components/Team/Registration/StudentFaculty";
import Professional from "../../components/Team/Registration/Professional";
import Startup from "../../components/Team/Registration/Startup";
import axios from "../../utils/axios";
import requireAuth from "../../utils/requireAuth";
import Loading from "../../components/Loading";
import Tracks from "../../components/Admin/Tracks";


import RouteHelper from "../../utils/routeHelper";
import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";


export const SPOCSubmittedTracks = (props) => {
  const [currentData, setCurrentData] = useState({});
  const [category, setCategory] = useState('');
  const [type,setType] = useState('')
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ids,setIds] = useState(0)
  const [track,setTrack] = useState(0)
  const [upd,setUpd] = useState(false);
  const [name,setName] = useState('');
  const [showForm,setShowForm] = useState(false)
  
  const handleUpd = (id,category,name,type) => {
  setUpd(!upd)
  setIds(id)
  setCategory(category)
  setName(name)
  setType(type)
  console.log(ids)
    
  }
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [val,setVal] = useState({
    category : '',
   name : '',
   type : ''
    
   })



   function handleSubmit(e) {
    e.preventDefault();
    
    if(val.category === '')
    {
      val.category = category
    }
    if(val.name === '')
    {
      val.name = name;
    }
    if(val.type === '')
    {
      val.type = type;
    }
    

    const data = val;
    toast.promise(
      axios().put(`/trackUpdate/${ids}`, data),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {

            axios()
            .get("/tracks")
            .then((response) => {
              setTracks(response.data.data);
              setIsLoading(false);
              console.log(tracks)
            });

            setIsSubmitting(false);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
        
      }
      
    );
    console.log(data)
  }


  const handleDelete = async (id) => {

    {/* tracks Delete part */ }

    if (await confirm("Are you sure you want to delete this track?")) {

      toast.promise(
        axios().post(`/trackDelete/${id}`),
        {
          pending: {
            render() {
              console.log("loding.....")
              setIsSubmitting(true);
              return "Loading....";
            },
          },
          success: {
            render() {
              console.log("success")
              axios()
              .get("/tracks")
              .then((response) => {
                setTracks(response.data.data);
                setIsLoading(false);
                console.log(tracks)
              });

              setIsSubmitting(false);
            
              return "Submitted successfully!";
            },
          },
          error: {
            render({ data }) {
              setIsSubmitting(false);
              let status = data.response.status;
              data = data.response.data;
              if (status === 422) {
                Object.entries(data.errors).forEach(([key, value]) => {
                  toast.error(value.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });

                return "There were errors in some fields";
              } else {
                return "Something went wrong!";
              }
            },
          },
    
   }
 );

  }
}
  let sl = 0;

  useEffect(() => {
    /*requireAuth().then((data) => {
      if (data.user.registered_for_current_challenge) {
        RouteHelper.redirect("/dashboard");
      }*/

      axios()
        .get("/tracks")
        .then((response) => {
          setTracks(response.data.data);
          setIsLoading(false);
          console.log(tracks)
        });
    ;
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  
  return ( 
    <div
      className="px-2 pt-3"
      style={{ marginLeft: props.sideBarOpen ? "260px" : "20px" }}
    >
      
      <div className="">
       
      { upd ? <><MDBBtn color="primary" className="col p-2" onClick = {handleUpd}>
		  
      Back   
</MDBBtn> 
{
  Object.entries(tracks).map(([category_name, tracks]) => <div className="d-flex align-items-center justify-content-center mt-4" key={category_name}>{tracks.map((track, id) => ids === track.id && <MDBContainer className="mt-4 px-2 d-flex align-center justify-content-center" key = {track.id}>

<MDBRow className="row d-flex justify-content-center">
          <MDBCol md="10">
            <MDBCard className="mt-4">
              <MDBCardBody className="shadow">
              <form>
                <p className="fs-2 text-dark  py-2 mb-2">
                  Category
                </p>
                <div>
                 
                  <MDBRow>
                    <div className="w-75">
                    <MDBInput
                label="Track Category"
                icon="envelope"
                group
                type="email"
                name = "category"
                validate
                error="wrong"
                success="right"
                defaultValue = {track.category}
              
                onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                
              />
                    </div>
                </MDBRow>
              </div>
                <p className="fs-2 text-dark  py-2 mb-2">
                  Track Name
                </p>
                <div>
                 
                  <MDBRow>
                    <div className="w-75">
                    <MDBInput
                label="Track Name"
                icon="envelope"
                name = "name"
                group
                type="email"
                validate
                error="wrong"
                success="right"
                defaultValue = {track.name}
               onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                
              />
                    </div>
                   

                    <div className=" mb-4">
                    <p className="fs-2 text-dark  py-2 mb-2">
                  Track type
                    </p>
                    <select
                        required={true}
                        className="form-control form-select"
                        name= "type"
                        label="Category"
                        defaultValue = {track.type}
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      >
                         <option value="">Choose the category</option>
                        <option value="student_faculty">Student / Faculty</option>
                        <option value="professional">Professional</option>
                        <option value="company">Company</option>
                        <option value="startup">Startup</option>
                        <option value="msme">MSME</option>
                        
                      </select>
                    </div>
        <div className="w-75 mt-3">
        <MDBBtn outline color="primary" onClick = {handleSubmit}>Submit</MDBBtn>
        </div>
      

                  </MDBRow>
                </div>
              </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

  </MDBContainer>)}
  <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
   </div>)
}


 </>: <> 
 {showForm ?<> <MDBBtn color="primary" className="col p-2" onClick = {(e) => setShowForm(!showForm)}>
		  
      Back   
</MDBBtn> <Tracks setTrack={setTracks} /> </> :  <>
 <div className={"justify-content-end mr-4 mb-3"}>
           
            <MDBBtn
              className={"mx-12 pull-right"}
              color={"success"}
              onClick={() => setShowForm(!showForm)}
            >
              Add Tracks
            </MDBBtn>
          
        </div>
      <h2 className="mx-3">Tracks</h2>
      <MDBTable hover striped bordered>
          <MDBTableHead color="primary" textWhite>
            <tr>
              <th>Track Id</th>
              <th>Track Category</th>
              <th>Track Name</th>
              <th>Participating Category</th>
              <th>Action</th>
              
            </tr>
          </MDBTableHead>
          
{console.log(tracks)}
           {          
           
           Object.entries(tracks).map(([category_name, tracks]) => <MDBTableBody key={category_name}>{tracks.map((track, index) => 
          
           <tr>
          <td>{sl+=1}</td>
          <td>{track.category}</td>
          <td>{track.name}</td>
          <td>{track.type}</td>
          <td>
			<div className="flex row gap-2 ">
      <MDBBtn color="primary" className="col p-2" onClick = {() => handleUpd(track.id,track.category,track.name,track.type)}>
		  
             Update
            
      </MDBBtn>
			<MDBBtn color="danger" className="col p-2" onClick = {(e) => {handleDelete(track.id)}}>
             Delete
            </MDBBtn>
			</div>
		  </td>
        </tr>
          
           
          
           )} </MDBTableBody>)}
          
        
          
        </MDBTable>
        </>
        }</>   }
        
      
      
      </div>
    </div>
  );
};
