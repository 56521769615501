import React, { useState } from "react";
import styled from "styled-components";
import SubMenu from "./SubMenu";
import * as HiIcons from "react-icons/hi";

const SidebarNav = styled.nav`
  background: #111342;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

export default function Sidebar(props) {
  return (
    <>
      <SidebarNav sidebar={true} className={"" + props.className ?? ""}>
        <SidebarWrap>
          <img
            className="mt-1 img-fluid sidebarLogo d-none d-md-block"
            style={{ paddingLeft: "27px", paddingTop: "15px" }}
            src="/MoE-logo.png"
            alt="logo"
          />
          <img
            className="hamburgerlogo mt-2 mr-2"
            src="/hamburger-menu.png"
            alt="image"
            style={{ right: "90%" }}
            onClick={() => {
              props.setShowSideBar(!props.showSideBar);
            }}
          />
          <div style={{ paddingTop: "1vh" }}></div>
          <div className="sidebarMenu">
            {props.sideBarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
            <SubMenu
              isLogout={true}
              item={{
                title: "Logout",
                path: "#",
                icon: <HiIcons.HiLogout />,
              }}
            />
          </div>
        </SidebarWrap>
      </SidebarNav>
    </>
  );
}
