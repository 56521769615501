import React from "react";
import { useState } from "react";
import { setChallenge } from "../../../utils/auth";

export default function SubmitIdea(props) {
  const [user, setUser] = useState(props.user);

  function renderChallengeStage(stage) {
    switch (stage) {
      case 1:
        return "Idea Submission";
      case 2:
        return "Product Development";
      case 3:
        return "Field Implementation";
    }
  }

  return (
    <div>
      <div class="container">
        <div class="row justify-content-center">
          {user.challenges
            .filter((challenge) => challenge.stage === 1)
            .map((challenge, index) => (
              <div className="col-md-6" key={index}>
                <div className="card outCard m-4">
                  <div
                    className="card-body text-white pl-0 pr-0"
                    style={{
                      paddingLeft: "0",
                      paddingRight: "0",
                      paddingBottom: "0",
                    }}
                  >
                    <h5 className="card-title text-center mb-2">
                      {challenge.name.toUpperCase()}
                    </h5>
                    <img
                      style={{
                        height: "150px",
                        width: "150px",
                        objectFit: "contain",
                      }}
                      className="img-fluid"
                      src={`\\${challenge.image}`}
                      alt="Challenge Image"
                    />

                    {challenge.stage !== 0 && (
                      <p>
                        <span
                          style={{
                            padding: "15px",
                            display: "inline-block",
                            borderRadius: "5px",
                            color: "white",
                          }}
                          className={
                            challenge.stage === 1 && challenge.registration_open
                              ? "bg-success"
                              : "bg-danger"
                          }
                        >
                          {challenge.stage === 1 && challenge.registration_open
                            ? "Idea Submission is open"
                            : "Idea Submission is closed"}{" "}
                        </span>
                        <br />
                        <br />

                        <span
                          style={{
                            padding: "15px",
                            display: "inline-block",
                            borderRadius: "5px",
                            color: "white",
                          }}
                          className={
                            challenge.idea_submitted
                              ? "bg-success"
                              : "bg-danger"
                          }
                        >
                          {challenge.idea_submitted
                            ? "Registered and Submitted Idea"
                            : user?.registered_challenges?.indexOf(
                                challenge.id
                              ) !== -1
                            ? "Registered and Not Submitted Idea"
                            : "Not Registered"}{" "}
                        </span>
                        <br />
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <button
                          style={{
                            borderRadius: "0px!important",
                            width: "100%",
                            backgroundColor: "#272c93",
                            color: "#ffffff",
                          }}
                          disabled={
                            user?.registered_challenges?.indexOf(
                              challenge.id
                            ) === -1 && !challenge.registration_open
                          }
                          onClick={() => setChallenge(challenge.id)}
                          className="btn btnStage rounded-0"
                        >
                          {challenge.stage === 0
                            ? "Coming Soon"
                            : user?.registered_challenges?.indexOf(
                                challenge.id
                              ) === -1
                            ? challenge.registration_open
                              ? "Register"
                              : "Closed"
                            : "Stages"}
                        </button>
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        {challenge.details && (
                          <p
                            style={{
                              marginBottom: "0",
                            }}
                          >
                            <a
                              style={{
                                fontSize: "16px",
                                borderRadius: "0px!important",
                                width: "100%",
                              }}
                              className="btn btn-warning rounded-0"
                              target="_blank"
                              href={challenge.link}
                            >
                              {challenge.details}
                            </a>{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
