import React, { useEffect } from "react";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentFaculty from "../../components/Team/Registration/StudentFaculty";
import Startup from "../../components/Team/Registration/Startup";
import Professional from "../../components/Team/Registration/Professional";
import RouteHelper from "../../utils/routeHelper";
import Loading from "../../components/Loading";
import axios from "../../utils/axios";
import Company from "../../components/Team/Registration/Company";
import Msme from "../../components/Team/Registration/Msme";
import auth from "../../utils/auth";

export default function RegisterTeam(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [track, setTrack] = useState(0);
  const [previousData, setPreviousData] = useState({});

  useEffect(() => {
    if (props.user.registered_for_current_challenge) {
      return RouteHelper.redirect("/team");
    }

    axios()
      .get("/tracks")
      .then((response) => {
        setTracks(response.data.data);
        if (props.user?.registered_challenges?.length > 0) {
          axios()
            .get("/challenges/previous/team")
            .then((response) => {
              if (response.data !== false) {
                setPreviousData(response.data.data);
              }
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  function categorySwitchRender() {
    switch (category) {
      case "student_faculty":
        return (
          <StudentFaculty
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
      case "professional":
        return (
          <Professional
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
      case "startup":
        return (
          <Startup
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
      case "company":
        return (
          <Company
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
      case "msme":
        return (
          <Msme
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
      //Add new forms here
      default:
        return (
          <Startup
            previous={previousData}
            setCategory={setCategory}
            track={track}
          />
        );
    }
  }

  if (category !== false) {
    return (
      <div className="mb-4">
        {categorySwitchRender()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }

  return (
    <div>
      <div className=" px-4 py-4 d-flex justify-content-end">
        <MDBBtn className="py-2" color="danger" onClick={() => auth().logout()}>
          Logout
        </MDBBtn>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <MDBContainer className="mt-2 px-1">
          <MDBRow className="row d-flex justify-content-center mt-2">
            <MDBCol md="9">
              <MDBCard className="mt-2">
                <MDBCardBody className="shadow">
                  <div className="row">
                    <p className="col-md-4 offset-md-4 mb-4 fw-bolder fs-2 text-dark text-center">
                      Register Team
                    </p>
                    {previousData.track_name
                      ? `You were registered as ${previousData.track_name}. Click on the track as per
                        your current status. Or, if you want to participate in the
                        same track, you will have to confirm your details once.`
                      : ""}
                  </div>

                  <div>
                    <div className={""}>
                      {Object.entries(tracks).map(([key, category]) => (
                        <MDBRow className="mb-4">
                          <h4
                            className={
                              "custom-bg mb-4 mt-1 text-center py-2 col-md-2 fw-bold"
                            }
                            style={{
                              color: key == "Track 1" ? "#135cc5" : "#7749ac",
                            }}
                          >
                            {key} :
                          </h4>
                          {/* <MDBRow key={key} className="justify-content-center"> */}

                          {category.map((t, index) => (
                            <MDBCol
                              md={
                                t.name == "Research scholars" ||
                                t.name == "Indian Companies"
                                  ? 3
                                  : 2
                              }
                              className={""}
                              key={index}
                            >
                              <MDBBtn
                                className={
                                  key == "Track 1"
                                    ? "track1-btn-grad"
                                    : "track2-btn-grad"
                                }
                                onClick={() => {
                                  setCategory(t.type);
                                  setTrack(t);
                                }}
                              >
                                {t.name}
                              </MDBBtn>
                            </MDBCol>
                          ))}
                          <MDBCol
                            className={"mb-0"}
                            md={key === "Track 1" ? 2 : 1}
                          ></MDBCol>
                          {/* </MDBRow> */}
                        </MDBRow>
                      ))}
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}
