import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import RouteHelper from "../../../utils/routeHelper";

export default function Professional(props) {
  const previous =
    props.previous?.category === "professional" ? props.previous : false;

  const [employed, setEmployed] = useState(
    previous ? previous?.members[0]?.professional_employed : null
  );

  const [isLoading, setIsLoading] = useState(false);

  function handleFormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("category", "professional");

    toast.promise(
      axios().post(`/challenges/register/${props.track.id}`, data),
      {
        pending: {
          render() {
            setIsLoading(true);
            return "Registering......";
          },
        },
        success: {
          render() {
            setIsLoading(false);
            RouteHelper.redirect("/dashboard");
            return "Team registered successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsLoading(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                value.forEach((v) =>
                  toast.error(v.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                );
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-around ">
          <MDBBtn color="primary" onClick={() => props.setCategory(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4 mt-4">
              {props.track.name} Registration
            </p>
            <form onSubmit={handleFormSubmit}>
              <h6 className="mb-2 pb-1 fw-bold">Team Name: </h6>
              <MDBInput
                required={true}
                label="Name"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name="name"
                defaultValue={previous ? previous?.members[0]?.name : ""}
              />
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Education </h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"education_info"}
                  defaultValue={
                    previous ? previous?.members[0]?.education_info : ""
                  }
                >
                  <option value="Diploma">Diploma</option>
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                  <option value="Ph.D">PhD</option>
                </select>
              </div>
              <br />
              <h6 class="mb-2 pb-1 fw-bold">Employed </h6>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="professional_employed"
                  id="professional_employed_yes"
                  value={1}
                  required={true}
                  defaultChecked={
                    previous && previous?.members[0]?.professional_employed
                  }
                  onChange={() => setEmployed(true)}
                />
                <label
                  class="form-check-label"
                  htmlFor="professional_employed_yes"
                >
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="professional_employed"
                  id="professional_employed_no"
                  value={0}
                  required={true}
                  defaultChecked={
                    previous
                      ? !previous?.members[0]?.professional_employed
                      : false
                  }
                  onChange={() => setEmployed(false)}
                />
                <label
                  class="form-check-label"
                  htmlFor={"professional_employed_no"}
                >
                  No
                </label>
              </div>
              <br></br>
              <br></br>
              {employed && (
                <div>
                  {" "}
                  <h6 class="mb-2 pb-1 fw-bold">Company Name: </h6>
                  <MDBInput
                    label="Company Name"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name={"professional_employed_firm"}
                    required={true}
                    defaultValue={
                      previous
                        ? previous?.members[0]?.professional_employed_firm
                        : ""
                    }
                  />
                  <br />
                  <h6 className="mb-2 pb-1 fw-bold">Designation: </h6>
                  <MDBInput
                    label="Designation"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name={"professional_employed_designation"}
                    required={true}
                    defaultValue={
                      previous
                        ? previous?.members[0]
                            ?.professional_employed_designation
                        : ""
                    }
                  />
                  <br />
                  <h6 className="mb-2 pb-1 fw-bold">Job Title: </h6>
                  <MDBInput
                    label="Job Title"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name={"professional_employed_job_title"}
                    defaultValue={
                      previous
                        ? previous?.members[0]?.professional_employed_job_title
                        : ""
                    }
                    required={true}
                  />
                  <br />
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Upload ID card of the company employed (File Type: PDF,
                      Max Size:10MB)
                      {/*{previous?.members[0]?.professional_employed_id_card_file*/}
                      {/*  ? "(Leave empty to use previous ID card file)"*/}
                      {/*  : ""}*/}:
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="file"
                      validate
                      error="wrong"
                      success="right"
                      name="professional_employed_id_card_file"
                      accept={".pdf"}
                    />
                  </div>
                  <br></br>
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Upload ID proof issued by Government (File Type: PDF, Max
                      Size:10MB)
                      {/*{previous?.members[0]?.professional_govt_id_proof_file*/}
                      {/*  ? "(Leave empty to use previous Government ID proof file)"*/}
                      {/*  : ""}*/}:
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="file"
                      validate
                      error="wrong"
                      success="right"
                      name="professional_govt_id_proof_file"
                      accept={".pdf"}
                    />
                  </div>
                  <br></br>
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Upload address proof issued by Government (File Type: PDF,
                      Max Size:10MB)
                      {/*{previous?.members[0]*/}
                      {/*  ?.professional_govt_address_proof_file*/}
                      {/*  ? "(Leave empty to use previous Government address proof file)"*/}
                      {/*  : ""}*/}:
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="file"
                      validate
                      error="wrong"
                      success="right"
                      name="professional_govt_address_proof_file"
                      accept={".pdf"}
                    />
                  </div>
                  <br></br>
                </div>
              )}
              {employed === false && (
                <div>
                  <h6 className="mb-2 pb-1 fw-bold">Current Occupation: </h6>
                  <MDBInput
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name={"professional_current_occupation"}
                    defaultValue={
                      previous
                        ? previous?.members[0]?.professional_current_occupation
                        : ""
                    }
                    required={true}
                  />
                  <br />
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Upload ID proof issued by Government (File Type: PDF, Max
                      Size:10MB)
                      {/*{previous?.members[0]?.professional_govt_id_proof_file*/}
                      {/*  ? "(Leave empty to use previous Government ID proof file)"*/}
                      {/*  : ""}*/}:
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="file"
                      validate
                      error="wrong"
                      success="right"
                      name="professional_govt_id_proof_file"
                      accept={".pdf"}
                    />
                  </div>
                  <br></br>
                  <div>
                    <h6 className="mb-2 pb-1 fw-bold">
                      Upload address proof issued by Government (File Type: PDF,
                      Max Size:10MB)
                      {/*{previous?.members[0]*/}
                      {/*  ?.professional_govt_address_proof_file*/}
                      {/*  ? "(Leave empty to use previous Government address proof file)"*/}
                      {/*  : ""}*/}:
                    </h6>
                    <MDBInput
                      required={true}
                      group
                      type="file"
                      validate
                      error="wrong"
                      success="right"
                      name="professional_govt_address_proof_file"
                      accept={".pdf"}
                    />
                  </div>
                  <br></br>
                </div>
              )}
              <div className="text-center">
                <MDBBtn color="primary" disabled={isLoading}>
                  {isLoading ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBModalBody>
  );
}
