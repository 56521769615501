import React, { useRef, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import Startup from "./Startup";
import Professional from "./Professional";
import StudentFaculty from "./StudentFaculty";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Base(props) {
  const [isLoading, setIsLoading] = useState(false);

  function handleFormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("category", props.category === "mentor" ? "mentor" : "member");
    toast.promise(
      axios().post(
        props?.data?.name
          ? `/challenges/team/${props?.data?.id}`
          : "/challenges/team/add",
        data
      ),
      {
        pending: {
          render() {
            setIsLoading(true);
            return "Adding......";
          },
        },
        success: {
          render({ data }) {
            setIsLoading(false);
            props.setTeam(data.data.data);
            props.setMenu(0);
            return "Member added Successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsLoading(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                value.forEach((v) =>
                  toast.error(v.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                );
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  function renderCategoryFields() {
    if (props.category === "student_faculty") {
      return <StudentFaculty data={props.data} />;
    }
    if (
      props.category === "startup" ||
      props.category === "company" ||
      props.category === "msme"
    ) {
      return <Startup data={props.data} category={props.category} />;
    }
    if (props.category === "professional") {
      return <Professional data={props.data} />;
    }

    if (props.category === "mentor") {
      return (
        <div>
          {" "}
          <h6 className="mb-2 pb-1 fw-bold">Occupation: </h6>
          <MDBInput
            required={true}
            label="Occupation"
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name="occupation"
            defaultValue={props?.data?.occupation}
          />
          <br></br>
        </div>
      );
    }

    return <div></div>;
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setMenu(0)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4">
              {!props?.data?.name
                ? "Add Team " +
                  (props.category === "mentor" ? "Mentor" : "Member")
                : "Edit Team " +
                  (props.category === "mentor"
                    ? "Mentor"
                    : props?.data?.role === "leader"
                    ? "Leader"
                    : "Member")}
            </p>
            <form onSubmit={handleFormSubmit}>
              <div>
                <div>
                  <div className="text-dark">
                    <h6 className="mb-2 pb-1 fw-bold">Name: </h6>
                    <MDBInput
                      required={true}
                      label="Name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      name={`name`}
                      defaultValue={props?.data?.name}
                    />
                    <br></br>
                    {props?.data?.role !== "leader" && (
                      <div>
                        <h6 className="mb-2 pb-1 fw-bold">Email: </h6>
                        <MDBInput
                          required={true}
                          label="Email"
                          icon="envelope"
                          group
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          name={`email`}
                          defaultValue={props?.data?.email}
                        />
                        <br></br>
                      </div>
                    )}
                    <h6 className="mb-2 pb-1 fw-bold">Mobile Number: </h6>
                    <MDBInput
                      required={true}
                      label="Mobile Number"
                      icon="tag"
                      group
                      type="number"
                      validate
                      error="wrong"
                      success="right"
                      name={`phone`}
                      defaultValue={props?.data?.phone}
                    />
                  </div>
                  <br></br>
                  <div className="col-md-6 mb-4">
                    <h6 className="mb-2 pb-1 fw-bold">Gender: </h6>

                    <div className="form-check form-check-inline">
                      <input
                        required={true}
                        className="form-check-input"
                        type="radio"
                        name={`gender`}
                        id={"femaleGender"}
                        value="female"
                        defaultChecked={props?.data?.gender === "female"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"femaleGender"}
                      >
                        Female
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        required={true}
                        className="form-check-input"
                        type="radio"
                        name={`gender`}
                        id={"maleGender"}
                        value="male"
                        defaultChecked={props?.data?.gender === "male"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"maleGender"}
                      >
                        Male
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        required={true}
                        className="form-check-input"
                        type="radio"
                        name={`gender`}
                        id={"otherGender"}
                        value="other"
                        defaultChecked={props?.data?.gender === "other"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"otherGender"}
                      >
                        Other
                      </label>
                    </div>
                  </div>
                  <h6 className="mb-2 pb-1 fw-bold">Education </h6>
                </div>
                <div>
                  <select
                    required={true}
                    className="form-control form-select"
                    name={`education_info`}
                    defaultValue={props?.data?.education_info}
                  >
                    <option value="Diploma">Diploma</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                    <option value="Ph.D">PhD</option>
                  </select>
                </div>
                <br />
                {renderCategoryFields()}
              </div>
              <br />
              <div className="text-center">
                <MDBBtn color="primary" disabled={isLoading}>
                  {isLoading ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MDBModalBody>
  );
}
