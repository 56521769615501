import { useState } from "react";
import { MDBInput } from "mdb-react-ui-kit";

export default function Startup(props) {
  const [startupRole, setStartupRole] = useState(
    props?.data?.role_in_startup_company_msme
      ? ["CCO", "CTO", "Founder", "Co Founder"].includes(
          props?.data?.role_in_startup_company_msme
        )
        ? props?.data?.role_in_startup_company_msme
        : "Others"
      : "CCO"
  );
  return (
    <div>
      <h6 className="mb-2 pb-1 fw-bold">Role in {props.category}:</h6>
      <div>
        <select
          required={true}
          className="form-control form-select"
          name={"role_in_startup_company_msme"}
          defaultValue={startupRole}
          onChange={(e) => setStartupRole(e.target.value)}
        >
          <option value="CCO">CCO</option>
          <option value="CTO">CTO</option>
          <option value="Founder">Founder</option>
          <option value="Co Founder">Co Founder</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <br />
      {startupRole === "Others" && (
        <div>
          <h6 className="mb-2 pb-1 fw-bold">Specify role in startup: </h6>
          <MDBInput
            required={true}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            name="role_in_startup_company_msme"
            defaultValue={props?.data?.role_in_startup_company_msme}
          />
          <br />
        </div>
      )}
    </div>
  );
}
