
export default function Landing()
{
    return (

<div className = "d-flex flex-row justify-content-center align-self-center mt-4">
<div class="card w-40 m-4 bg-dark" >
<img class="card-img-top" style = {{height : '30vh'}} src="/onion-1.png" alt="Onion" />
  <div class="card-body text-white">
    <h5 class="card-title">Grand Onion Challenge</h5>
    <p class="card-text">Stage 1 Completed</p>
    <a href="#" class="btn btn-primary " style = {{display : "flex", alignItems : "center", justifyContent : "center"}}>Login </a>
  </div>
</div>
<div class="card w-40 m-4 bg-dark" >
<img class="card-img-top" style = {{height : '30vh'}} src="/tomato1.png" alt="Onion" />
  <div class="card-body text-white">
    <h5 class="card-title">Grand Challenge</h5>
    <p class="card-text">Submission Open</p>
   

    <a href="#" class="btn btn-primary "  style = {{display : "flex", alignItems : "center", justifyContent : "center"}}>Register Now!</a>
   
  </div>
</div>
</div>
    )
}
