import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { MDBBtn } from "mdb-react-ui-kit";
import ProblemSubmission from "./ProblemSubmission";

export default function Problems(props) {
  const [problems, setProblems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPs, setCurrentPs] = useState(false);

  useEffect(() => {
    axios()
      .get("/problems")
      .then((response) => {
        setProblems(response.data.data);
        setIsLoading(false);
      });
  }, []);

  function getTotal(category) {
    let total = 0;

    problems.forEach((problem) => {
      total += problem.submissions[category] ?? 0;
    });

    return total;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (currentPs !== false) {
    return (
      <ProblemSubmission
        problem={problems[currentPs]}
        setCurrentPs={setCurrentPs}
        tracks={props.tracks}
      />
    );
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Idea Submission</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "#111342",
                      color: "#ffffff",
                    }}
                  >
                    <th scope="col">Sr.NO</th>
                    <th scope="col">PS Title</th>
                    {Object.entries(props.tracks).map(([key, category]) => (
                      <th key={key} scope="col">
                        No. of Ideas Submitted in {key}
                      </th>
                    ))}
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {problems.map((problem, index) => {
                    return (
                      <tr key={index} style={{ textAlign: "left" }}>
                        <th scope="row">{index + 1}</th>
                        <td>{problem.title}</td>
                        {Object.entries(props.tracks).map(([key, category]) => (
                          <td key={key}>{problem.submissions[key] ?? 0}</td>
                        ))}
                        <td>
                          <MDBBtn
                            color="primary"
                            onClick={() => setCurrentPs(index)}
                          >
                            View
                          </MDBBtn>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <th colSpan={2} className="text-center">
                      Total
                    </th>
                    {Object.entries(props.tracks).map(([key, category]) => (
                      <td key={key}>{getTotal(key)}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
