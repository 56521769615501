import { useEffect, useRef, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { MDBBtn } from "mdb-react-ui-kit";
import { useDownloadExcel } from "react-export-table-to-excel";

export default function Stage2Submissions(props) {
  const [submissions, setSubmissions] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const tableRef = useRef("TEST");

  const { onDownload } = useDownloadExcel({
    currentTableRef: (() => tableRef.current)(),
    filename: "stage2Submissions",
    sheet: "Submissions",
  });

  useEffect(() => {
    axios()
      .get(`/submissions/stage2`)
      .then((response) => {
        setSubmissions(response.data.data);
        setIsLoading(false);

        tableRef.current = (
          <table className="table table-bordered">
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "#111342",
                  color: "#ffffff",
                }}
              >
                <th scope="col">Sr.NO</th>
                <th>Idea ID</th>
                <th scope="col">Idea title</th>
                <th scope="col">Description</th>
                <th scope="col">Abstract</th>
                <th scope="col">Team Details</th>
                <th>Idea/Other Document</th>
                {/*<th>Other Document</th>*/}
              </tr>
            </thead>
            <tbody>
              {(submissions ?? []).map((submission, index) => (
                <tr className="idaSubTbl" style={{ textAlign: "left" }}>
                  <td>{index + 1}</td>
                  <td>{submission.id}</td>
                  <td>{submission.title}</td>
                  <td>{submission.description.substring(0, 150)}</td>
                  <td>{submission.abstract.substring(0, 150)}</td>
                  <td>
                    {submission.team.name}
                    <br />
                    <span>
                      <strong>Name:</strong> {submission.team.leader.name}
                    </span>
                    <br />
                    <span>
                      <strong>Email:</strong> {submission.team.leader.email}
                    </span>
                    <br />
                    <span>
                      <strong>Phone No:</strong> {submission.team.leader.phone}
                    </span>
                  </td>
                  <td>
                    <div style={{ display: "block", marginBottom: "10px" }}>
                      {submission.idea_document_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2"
                          href={submission.idea_document_file}
                        >
                          View Idea Document
                        </MDBBtn>
                      ) : (
                        "Not Submitted"
                      )}
                    </div>
                    <div style={{ display: "block" }}>
                      {submission.other_document_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2"
                          href={submission.other_document_file}
                        >
                          View Other Document
                        </MDBBtn>
                      ) : (
                        "Not Submitted"
                      )}
                    </div>
                    <div style={{ display: "block" }}>
                      {submission.stage_2_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2"
                          href={submission.stage_2_file}
                        >
                          View Stage 2 Document
                        </MDBBtn>
                      ) : (
                        "Not Submitted"
                      )}
                    </div>
                  </td>
                  {/*<td>
                          {submission.other_document_file ? (
                            <MDBBtn
                              color="primary"
                              className="col p-2"
                              href={submission.other_document_file}
                            >
                              View Other Document
                            </MDBBtn>
                          ) : (
                            "Not Submitted"
                          )}
                        </td>*/}
                </tr>
              ))}
            </tbody>
          </table>
        );
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 text-center">
            <h4>Stage 2 Submissions</h4>
          </div>
        </div>
        {submissions.length ? (
          <div className="row my-2">
            <div className="col-md-12">
              <div className="table-responsive">
                <div class={"d-flex flex-row justify-content-end"}>
                  <MDBBtn color="primary" onClick={onDownload}>
                    Export
                  </MDBBtn>
                </div>
                <table className="table table-bordered" ref={tableRef}>
                  <thead>
                    <tr
                      style={{
                        textAlign: "center",
                        backgroundColor: "#111342",
                        color: "#ffffff",
                      }}
                    >
                      <th scope="col">Sr.NO</th>
                      <th>Idea ID</th>
                      <th scope="col">Idea title</th>
                      <th scope="col">Description</th>
                      <th scope="col">Abstract</th>
                      <th scope="col">Team Details</th>
                      <th>Idea/Other Document</th>
                      {/*<th>Other Document</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {(submissions ?? []).map((submission, index) => (
                      <tr className="idaSubTbl" style={{ textAlign: "left" }}>
                        <td>{index + 1}</td>
                        <td>{submission.id}</td>
                        <td>{submission.title}</td>
                        <td>{submission.description.substring(0, 150)}</td>
                        <td>{submission.abstract.substring(0, 150)}</td>
                        <td>
                          {submission.team.name}
                          <br />
                          <span>
                            <strong>Name:</strong> {submission.team.leader.name}
                          </span>
                          <br />
                          <span>
                            <strong>Email:</strong>{" "}
                            {submission.team.leader.email}
                          </span>
                          <br />
                          <span>
                            <strong>Phone No:</strong>{" "}
                            {submission.team.leader.phone}
                          </span>
                        </td>
                        <td>
                          <div
                            style={{ display: "block", marginBottom: "10px" }}
                          >
                            {submission.idea_document_file ? (
                              <MDBBtn
                                color="primary"
                                className="col p-2"
                                href={submission.idea_document_file}
                              >
                                View Idea Document
                              </MDBBtn>
                            ) : (
                              "Not Submitted"
                            )}
                          </div>
                          <div style={{ display: "block" }}>
                            {submission.other_document_file ? (
                              <MDBBtn
                                color="primary"
                                className="col p-2"
                                href={submission.other_document_file}
                              >
                                View Other Document
                              </MDBBtn>
                            ) : (
                              "Not Submitted"
                            )}
                          </div>
                          <div style={{ display: "block" }}>
                            {submission.stage_2_file ? (
                              <MDBBtn
                                color="primary"
                                className="col p-2"
                                href={submission.stage_2_file}
                              >
                                View Stage 2 Document
                              </MDBBtn>
                            ) : (
                              "Not Submitted"
                            )}
                          </div>
                        </td>
                        {/*<td>
                          {submission.other_document_file ? (
                            <MDBBtn
                              color="primary"
                              className="col p-2"
                              href={submission.other_document_file}
                            >
                              View Other Document
                            </MDBBtn>
                          ) : (
                            "Not Submitted"
                          )}
                        </td>*/}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-warning">No submissions yet!</div>
        )}
      </div>
    </section>
  );
}
