import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { MDBBtn } from "mdb-react-ui-kit";
import download from "js-file-download";

export default function ProblemSubmission(props) {
  const [submissions, setSubmissions] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios()
      .get(`/problems/${props.problem.id}/submissions`)
      .then((response) => {
        console.log(response.data.data);
        setSubmissions(response.data.data);
        setIsLoading(false);
      });
  }, []);

  function handleDownload() {
    axios()
      .post(
        `/problems/${props.problem.id}/submissions/export`,
        {},
        {
          responseType: "blob",
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      )
      .then((response) => {
        download(response.data, "grand_challenge_submissions.xlsx");
      })
      .catch((error) => {
        alert("The file couldn't be downloaded");
      });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-2">
            {/*<MDBBtn color="primary" onClick={() => props.setCurrentPs(false)}>
              Back
            </MDBBtn>*/}
          </div>
          <div className="col-md-8 text-center">
            <h4>Idea Submissions for {props.problem.title}</h4>
          </div>
          <div className="d-flex justify-content-end">
            <MDBBtn onClick={() => handleDownload()} color="secondary">
              Export
            </MDBBtn>
          </div>
        </div>
        {submissions.length ? (
          <div className="row my-2">
            <div className="col-md-12">
              <div className="table-responsive text-center">
                <table className="table table-bordered text">
                  <thead>
                    <tr align="center">
                      <th scope="col">Sr.NO</th>
                      <th>Track Name</th>
                      <th scope="col">Idea title</th>
                      <th scope="col">Description</th>
                      <th scope="col">Abstract</th>
                      <th scope="col">Team Details</th>
                      <th>Idea/Other Document</th>
                      {/*<th>Other Document</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {(submissions ?? []).map((submission, index) => (
                      <tr className="idaSubTbl" style={{ textAlign: "left" }}>
                        <td>{index + 1}</td>
                        <td>{submission.team.track.name}</td>
                        <td>{submission.title}</td>
                        <td>{submission.description.substring(0, 150)}</td>
                        <td>{submission.abstract.substring(0, 150)}</td>
                        <td>
                          {submission.team.name}
                          <br />
                          <span>
                            <strong>Name:</strong> {submission.team.leader.name}
                          </span>
                          <br />
                          <span>
                            <strong>Email:</strong>{" "}
                            {submission.team.leader.email}
                          </span>
                          <br />
                          <span>
                            <strong>Phone No:</strong>{" "}
                            {submission.team.leader.phone}
                          </span>
                        </td>
                        <td>
                          <div
                            style={{ display: "block", marginBottom: "10px" }}
                          >
                            {submission.idea_document_file ? (
                              <MDBBtn
                                color="primary"
                                className="col p-2"
                                href={submission.idea_document_file}
                              >
                                View Idea Document
                              </MDBBtn>
                            ) : (
                              "Not Submitted"
                            )}
                          </div>
                          <div style={{ display: "block" }}>
                            {submission.other_document_file ? (
                              <MDBBtn
                                color="primary"
                                className="col p-2"
                                href={submission.other_document_file}
                              >
                                View Other Document
                              </MDBBtn>
                            ) : (
                              "Not Submitted"
                            )}
                          </div>
                        </td>
                        {/*<td>
                          {submission.other_document_file ? (
                            <MDBBtn
                              color="primary"
                              className="col p-2"
                              href={submission.other_document_file}
                            >
                              View Other Document
                            </MDBBtn>
                          ) : (
                            "Not Submitted"
                          )}
                        </td>*/}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-warning">
            No submissions for this problem statement yet!
          </div>
        )}
      </div>
    </section>
  );
}
