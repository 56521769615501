import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import Stage2Form from "../../components/Team/Stage2Form";
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";

export const Stage2Submissions = (props) => {
  const [user, setUser] = useState(props.user);
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentSub, setCurrentSub] = useState(false);

  useEffect(() => {
    axios()
      .get("/submissions/2")
      .then((response) => {
        setSubmissions(response.data.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (currentSub !== false) {
    return (
      <Stage2Form
        setCurrentSub={setCurrentSub}
        submission={submissions[currentSub]}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <h2 className="mx-3">Stage 2 Submission</h2>
      <div className="">
        {submissions.length ? (
          <MDBTable hover striped bordered>
            <MDBTableHead color="primary" textWhite>
              <tr>
                <th>Submission ID</th>
                <th>Idea Title</th>
                <th>Idea Document</th>
                <th>Other Document</th>
                <th>Submitted At</th>
                <th>Vertical Title</th>
                <th>Stage 2 file</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {submissions.map((submission, index) => (
                <tr key={index}>
                  <td>{submission.id}</td>
                  <td>{submission.title}</td>
                  <td>
                    {submission.idea_document_file ? (
                      <MDBBtn
                        color="primary"
                        className="col p-2"
                        href={submission.idea_document_file}
                      >
                        View Idea Document
                      </MDBBtn>
                    ) : (
                      "Not Submitted"
                    )}
                  </td>
                  <td>
                    {submission.other_document_file ? (
                      <MDBBtn
                        color="primary"
                        className="col p-2"
                        href={submission.other_document_file}
                      >
                        View Other Document
                      </MDBBtn>
                    ) : (
                      "Not Submitted"
                    )}
                  </td>
                  <td>{submission.created_at}</td>
                  <td>{submission.problem_statement.title}</td>
                  <td>
                    <div style={{ display: "block" }}>
                      {submission.stage_2_file ? (
                        <MDBBtn
                          color="primary"
                          className="col p-2"
                          href={submission.stage_2_file}
                        >
                          View Stage 2 Document
                        </MDBBtn>
                      ) : (
                        "Not Submitted"
                      )}
                    </div>
                  </td>
                  <td>
                    <span>{submission.status}</span>
                  </td>
                  <td>
                    <MDBBtn
                      color={
                        user.current_challenge.stage === 2 &&
                        user.current_challenge.submission_open
                          ? "success"
                          : "danger"
                      }
                      className="col p-2"
                      onClick={() =>
                        user.current_challenge.stage === 2 &&
                        user.current_challenge.submission_open
                          ? setCurrentSub(index)
                          : alert("SUBMISSION CLOSED!")
                      }
                      disabled={
                        !(
                          user.current_challenge.stage === 2 &&
                          user.current_challenge.submission_open
                        )
                      }
                    >
                      {user.current_challenge.stage === 2 &&
                      user.current_challenge.submission_open
                        ? "Submit"
                        : "Closed"}
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <h4 className={"text-center text-warning mb-4"}>
            No submissions yet!
          </h4>
        )}
      </div>
    </div>
  );
};
