
import React, { useEffect } from "react";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import auth from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentFaculty from "../../components/Team/Registration/StudentFaculty";
import Startup from "../../components/Team/Registration/Startup";
import Professional from "../../components/Team/Registration/Professional";
import requireAuth from "../../utils/requireAuth";
import RouteHelper from "../../utils/routeHelper";
import Loading from "../../components/Loading";
import axios from "../../utils/axios";

export default function TracksPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [track,setTrack] = useState('')
  const [tracks,setTracks] = useState([])
  const [studfac,setStudFac] = useState(0)
  const [startup,setStartUp] = useState(0)
  const [professional,setProfessional] = useState(0)
  //const [category, setCategory] = useState('');
 const [val,setVal] = useState({
  category : '',
 name : '',
 type : ''
  
 })
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleSubmit(e) {
    e.preventDefault();

    const data = val;
    toast.promise(
      axios().post(`/trackSubmit`, data),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {
            
            axios()
            .get("/tracks")
            .then((response) => {
              props.setTrack(response.data.data);
              setIsLoading(false);
              console.log(tracks)
            });

            setIsSubmitting(false);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
       
      }
    );
    console.log(data)
  }

 
  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      <MDBContainer className="mt-4 px-2 d-flex align-center justify-content-center">
        <MDBRow className="row d-flex justify-content-center">
          <MDBCol md="10">
            <MDBCard className="mt-4">
              <MDBCardBody className="shadow">
              <form>
                <p className="fs-2 text-dark  py-2 mb-2">
                  Category
                </p>
                <div>
                 
                  <MDBRow>
                    <div className="w-75">
                    <MDBInput
                label="Track Category"
                icon="envelope"
                group
                type="email"
                name = "category"
                validate
                error="wrong"
                success="right"
                onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                
              />
                    </div>
                </MDBRow>
              </div>
                <p className="fs-2 text-dark  py-2 mb-2">
                  Track Name
                </p>
                <div>
                 
                  <MDBRow>
                    <div className="w-75">
                    <MDBInput
                label="Track Name"
                icon="envelope"
                name = "name"
                group
                type="email"
                validate
                error="wrong"
                success="right"
               onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                
              />
                    </div>
                   

                    <div className=" mb-4">
                    <p className="fs-2 text-dark  py-2 mb-2">
                  Track type
                    </p>
                    <select
                        required={true}
                        className="form-control form-select"
                        name= "type"
                        label="Category"
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      >
                         <option value="">Choose the category</option>
                        <option value="student_faculty">Student / Faculty</option>
                        <option value="professional">Professional</option>
                        <option value="company">Company</option>
                        <option value="startup">Startup</option>
                        <option value="msme">MSME</option>
                        
                      </select>
                    </div>
        <div className="w-75 mt-3">
        <MDBBtn outline color="primary" onClick = {handleSubmit}>Submit</MDBBtn>
        </div>
      

                  </MDBRow>
                </div>
              </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

