import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar";
import { SidebarData as TeamSideBarData } from "../../components/Team/SideBar/SidebarData";
import { SidebarData as ChallengeSideBarData } from "../../components/Team/SideBar/ChallengesSidebarData";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { MyTeam } from "./TeamDetails";
import { ProblemStatements, SubmittedIdeas } from "./IdeaSubmission";
import Contact from "./ContactUs";
import { useEffect, useState } from "react";
import requireAuth from "../../utils/requireAuth";
import RouteHelper from "../../utils/routeHelper";
import Loading from "../../components/Loading";
import Profile from "./Profile";
import Staging from "./Challenges/Staging";
import { retrieve } from "../../utils/store";
import { checkValidChallenge } from "../../utils/auth";
import { Stage2Submissions } from "./Stage2Submissions";

export default function Base() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    requireAuth().then((data) => {
      setUser(data.user);

      if (data.user.challenge_role !== "participant") {
        return RouteHelper.redirectToRoleDashboard(data.user.challenge_role);
      }

      if (!retrieve("challenge")) {
        return RouteHelper.redirect("/challenges");
      }

      if (!data.user.registered_for_current_challenge) {
        return RouteHelper.redirect("/challenges/register");
      }

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const challenge = retrieve("challenge");

  if (!checkValidChallenge(challenge)) {
    RouteHelper.redirect("/challenges");
    return <Loading></Loading>;
  }

  const stage = localStorage.getItem("stage");
  if (!stage && location.pathname !== "/team/stages") {
    RouteHelper.redirect("/team/stages");
    return <Loading></Loading>;
  } else if (location.pathname === "/team/stages") {
    return (
      <div>
        <Header isSideBarPresent={true} />
        <SideBar
          sideBarData={ChallengeSideBarData}
          className="col-sm-3 col-md-2"
        />
        <div
          className="col-md-9 offset-md-2 col-sm-9 offset-sm-2"
          style={{ minHeight: "75.4vh" }}
        >
          <Staging user={user} />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header isSideBarPresent={true} />
      <div className="row" style={{ marginRight: "0px" }}>
        <SideBar sideBarData={TeamSideBarData} className="col-sm-3 col-md-2" />
        <div
          className="col-md-10 offset-md-2 col-sm-9 offset-sm-3"
          style={{ minHeight: "75.4vh" }}
        >
          <Routes>
            <Route
              path="/"
              element={<Navigate to="idea-submission/problem-statements" />}
            />
            <Route path="/my-team/*" element={<MyTeam user={user} />} />
            <Route
              path="/idea-submission/problem-statements"
              element={<ProblemStatements user={user} />}
            />
            <Route
              path="/idea-submission/submitted-ideas"
              element={<SubmittedIdeas user={user} />}
            />
            <Route
              path="/submissions/stage2"
              element={<Stage2Submissions user={user} />}
            />
            <Route path="/contact" element={<Contact user={user} />} />
            <Route path="/profile" element={<Profile user={user} />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}
