import React, { useRef, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import SelectSearch from "react-select-search";
import RouteHelper from "../../../utils/routeHelper";
//Refer index.css for styling React Select Search Component

export default function StudentFaculty(props) {
  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bhopal",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "The Dadra and Nagar Haveli and Daman and Diu",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const previous =
    props.previous?.category === "student_faculty" ? props.previous : false;

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(
    previous ? previous?.institute_state : "Andaman and Nicobar Islands"
  );
  const [district, setDistrict] = useState(
    previous ? previous?.institute_district : ""
  );
  const [institute, setInstitute] = useState(
    previous
      ? { name: previous?.institute_name, value: previous?.institute_id }
      : ""
  );

  function handleFormSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("category", "student_faculty");
    data.append("institute_id", institute.value);
    data.append("city", district);
    toast.promise(
      axios().post(`/challenges/register/${props.track.id}`, data),
      {
        pending: {
          render() {
            setIsLoading(true);
            return "Registering......";
          },
        },
        success: {
          render() {
            setIsLoading(false);
            RouteHelper.redirect("/dashboard");
            return "Team registered successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsLoading(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              console.log(data.errors);

              Object.entries(data.errors).forEach(([key, value]) => {
                value.forEach((v) =>
                  toast.error(v.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                );
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  function getDistricts() {
    return new Promise((resolve, reject) => {
      axios()
        .get(`districts/${state}`)
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  }

  function getColleges(query) {
    if (query === "") {
      return [
        {
          name: institute?.name ?? "Please enter institute name",
          value: institute?.value ?? "",
        },
      ];
    }
    return new Promise((resolve, reject) => {
      axios()
        .get(`colleges/${state}/${query}`)
        .then((response) =>
          resolve([...response.data.data, { value: "other", name: "Other" }])
        )
        .catch((error) => reject(error));
    });
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCategory(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <div className="text-dark">
            <p className="fs-2 text-center mb-4">
              {props.track.name} Registration
            </p>
            <form onSubmit={handleFormSubmit}>
              <h6 className="mb-2 pb-1 fw-bold">Team Name: </h6>
              <MDBInput
                required={true}
                label="Name"
                group
                type="text"
                validate
                error="wrong"
                success="right"
                name="name"
                defaultValue={previous ? previous.name : ""}
              />
              <br></br>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <h6 class="mb-2 pb-1 fw-bold">State: </h6>
                  <select
                    required={true}
                    className="form-control form-select"
                    name={`state`}
                    defaultValue={previous ? previous.institute_state : ""}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {states.map((s) => (
                      <option value={s}>{s}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6 mb-4">
                  <h6 class="mb-2 pb-1 fw-bold">City: </h6>
                  <SelectSearch
                    search={false}
                    value={district}
                    onChange={(value, obj) => {
                      setDistrict(value);
                    }}
                    defaultValue={district}
                    debounce={500}
                    getOptions={getDistricts}
                    name="district"
                    placeholder={"Please select a city"}
                    options={[{ name: "Please select a city", value: "" }]}
                  />
                </div>
              </div>
              <h6 className="mb-2 pb-1 fw-bold">Institute Name: </h6>
              <SelectSearch
                style={{ width: 100 }}
                search={true}
                value={institute.value}
                onChange={(value, obj) => {
                  setInstitute(obj);
                }}
                autoComplete={"on"}
                debounce={500}
                getOptions={getColleges}
                name="college"
                placeholder={"Please select an institute"}
                options={[{ name: "Please enter institute name", value: "" }]}
              />
              <br></br>
              {institute.value === "other" && (
                <div>
                  <h6 className="mb-2 pb-1 fw-bold">Enter Institute Name: </h6>
                  <MDBInput
                    required={true}
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    name="other_institute_name"
                    defaultValue={previous ? previous?.institute : ""}
                  />
                  <br></br>
                </div>
              )}
              <div>
                <h6 class="mb-2 pb-1 fw-bold">
                  Upload Bonafide Certificate from Institute (File Type: PDF,
                  Max Size:10MB) {/*{previous?.bonafide_file*/}
                  {/*  ? "(Leave empty to use previous bonafide file)"*/}
                  {/*  : ""}*/}:
                </h6>
                <MDBInput
                  required={true}
                  group
                  type="file"
                  validate
                  error="wrong"
                  success="right"
                  name="bonafide_file"
                  accept={".pdf"}
                />
              </div>
              <br></br>
              <br />
              <h4>Team Leader:</h4>
              <h6 className="mb-2 pb-1 fw-bold">Education </h6>
              <div>
                <select
                  required={true}
                  className="form-control form-select"
                  name={"education_info"}
                  defaultValue={
                    previous ? previous?.members[0]?.education_info : ""
                  }
                >
                  <option value="Diploma">Diploma</option>
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                  <option value="Ph.D">PhD</option>
                </select>
              </div>
              <br />
              <h6 className="mb-2 pb-1 fw-bold">Enrollment/ID Card Number: </h6>
              <MDBInput
                required={true}
                label="Enrollment/ID Card Number"
                group
                type="text"
                validate
                error="wrong"
                defaultValue={
                  previous ? previous?.members[0]?.enrollment_number : ""
                }
                success="right"
                name={"enrollment_number"}
              />
              <br></br>
              <br />
              <div className="text-center">
                <MDBBtn color="primary" disabled={isLoading}>
                  {isLoading ? "Submitting...." : "Submit"}
                </MDBBtn>
              </div>
            </form>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBModalBody>
  );
}
