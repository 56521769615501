import React from "react";
import { FaUsers, FaLightbulb, FaChessKing } from "react-icons/fa";
import { BsPatchQuestionFill } from "react-icons/bs";

export const SpocSidebarData = [
  {
    title: "Challenges",
    path: "/spoc/challenges",
    icon: <FaChessKing />,
  },
  {
    title: "Dashboard",
    path: "/spoc",
    icon: <FaUsers />,
  },
  {
    title: "Registration Detail",
    path: "/spoc/tracks/",
    icon: <FaLightbulb />,
  },
  {
    title: "Evaluators Detail",
    path: "/spoc/evaluators/",
    icon: <FaUsers />,
  },
  {
    title: "Idea Submission",
    path: "/spoc/problems",
    icon: <BsPatchQuestionFill />,
  },
  {
    title: "Stage 2 Submissions",
    path: "/spoc/submissions/stage2",
    icon: <BsPatchQuestionFill />,
  },
  {
    title: "Stage 3 Submissions",
    path: "/spoc/submissions/stage3",
    icon: <BsPatchQuestionFill />,
  },

  {
    title: "Tracks",
    path: "/spoc/viewtracks",
    icon: <BsPatchQuestionFill />,
  },

  {
    title: "Problems",
    path: "/spoc/viewproblems",
    icon: <BsPatchQuestionFill />,
  },
];
