import React from "react";
import {
  MDBContainer,
  MDBContainerFluid,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";

const Contact = (props) => {
  return (
    <div className="home px-2 pt-3">
     

        <MDBContainer fluid style={{
          backgroundColor: '#2d0e54',
          paddingBottom:'20px',
         }}>

         <MDBRow className="row d-flex justify-content-center mt-3">
          <MDBCol md="12" className="text-center">&nbsp;</MDBCol>
            <h4 className="contactHeading" style={{
              display:'block',
              textAlign:'center',
            }}>Contact Us</h4>
          </MDBRow>
          <MDBRow className="row d-flex justify-content-center mt-4">
          <MDBCol md="2" className="">&nbsp;</MDBCol>
            <MDBCol md="4" className="">
              <div className="footerBoxRight">
                <ul className="list-unstyled">
                  <li><i className="fa fa-envelope-open" aria-hidden="true"></i>&nbsp;&nbsp;For Onion: <a href="mailto:gc.onion@aicte-india.org" target="_blank">gc.onion@aicte-india.org</a></li>
                  <li><i className="fa fa-envelope-open" aria-hidden="true"></i>&nbsp;&nbsp;For Tomato: <a href="mailto:tgc@aicte-india.org" target="_blank">tgc@aicte-india.org</a></li>
                 
                </ul>
              </div>
            </MDBCol>
            <MDBCol md="4" className="">
              <div className="footerBoxRight">
                <ul className="list-unstyled">
                  <li><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;Phone: <a href="tel:011 2958 1509" target="_blank">011 2958 1509</a></li>
                </ul>
              </div>
            </MDBCol>
            <MDBCol md="2" className="">&nbsp;</MDBCol>
          </MDBRow>
        </MDBContainer>
    </div>
  );
};

export default Contact;
