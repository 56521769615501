export default [
  {
    id: 1,
    value: "Vertical 1A",
    label:
      "(Vertical 1A) Developing and popularizing improved varieties with multiple disease resistance and high shelf-life cultivation technology or practices for fresh market segment suiting to rainy season, high temperature and moisture stress.",
  },

  {
    id: 2,
    value: "Vertical 1B",
    label:
      "(Vertical 1B) Developing and popularizing improved varieties with multiple disease resistance and high shelf-life, cultivation technology or practices for processing segment and mechanization in production.",
  },
  {
    id: 3,
    value: "Vertical 2",
    label:
      "(Vertical 2) Designing and Disseminating information system pertaining to crop planning, production practices, market intelligence, farmer/ trader/ customer interface, etc. (Remote Sensing, Artificial Intelligences, App based platforms etc.)",
  },
  {
    id: 4,
    value: "Vertical 3",
    label:
      "(Vertical 3) Innovative post-harvest treatments, packaging and technologies for long term storage to minimize post- harvest losses during transportation and storage to avoid panic selling due to perishability",
  },
  {
    id: 5,
    value: "Vertical 4",
    label:
      "(Vertical 4) Developing value added products and processes for product diversification including utilization of processing waste, and innovative technologies for value addition to avoid panic selling due to perishability.",
  },
];
