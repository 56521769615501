import React, { useEffect } from "react";
import axios from "../../utils/axios";
import download from "js-file-download";
import "react-toastify/dist/ReactToastify.css";
import { MDBBtn } from "mdb-react-ui-kit";

export default function Tracks(props) {
  function handleDownload(onlyLeads) {
    axios()
      .post(
        onlyLeads ? "/tracks/export/leads" : "tracks/export",
        {},
        {
          responseType: "blob",
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      )
      .then((response) => {
        download(response.data, "grand_challenge_registrations.xlsx");
      })
      .catch((error) => {
        alert("The file couldn't be downloaded");
      });
  }

  function countTotal(category, is_submissions = false) {
    let total = 0;

    if (is_submissions) {
      category.forEach((category) => {
        total += category.submissions_count;
      });
    } else {
      category.forEach((category) => {
        total += category.registrations;
      });
    }

    return total;
  }

  return (
    <section className="registration-form mt-4 mb-4">
      <div className="container mb-4">
        <div className="row mb-4">
          <div className="offset-md-4 col-md-4 text-center">
            <h1>Registration Details</h1>
          </div>
          <div className={"col-md-4"}>
            {/*<span className="downloadBtn">
              <button onClick={() => handleDownload()}>Download</button>
            </span>*/}
          </div>
        </div>
        <div className="row">
          <div className={"col-md-12"}>
            <span className="downloadBtn">
              <button onClick={() => handleDownload()}>Team Details</button>
            </span>
            <span className="downloadBtn">
              <button onClick={() => handleDownload(true)}>
                Team Lead Details
              </button>
            </span>
          </div>
        </div>
        {Object.entries(props.tracks).map(([key, category]) => (
          <div className="row my-2">
            <h3 className="justify-center col-md-12">{key}</h3>
            <div className="col-md-12">
                        
        
              <div className="teamTable">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "#111342",
                          color: "#ffffff",
                        }}
                      >
                        <th scope="col">Sr.NO</th>
                        <th scope="col">Track Name</th>
                        <th scope="col">No of Registrations</th>
                        <th scope="col">No of Idea Submissions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category.map((track, index) => {
                        return (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <strong
                                style={{ fontSize: "15px", fontWeight: "600" }}
                              >
                                {track.name}
                              </strong>
                            </td>
                            <td>
                              <a href={`teams/${track.id}`}>
                                <span
                                  class="badge badge-pill badge-danger"
                                  style={{ fontSize: "14px" }}
                                >
                                  {track.registrations}
                                </span>
                              </a>
                            </td>
                            <td>
                              <span
                                class="badge badge-pill badge-info"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                <a href={`submissions/${track.id}`}>
                                  {track.submissions_count}
                                </a>
                              </span>
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <th className="text-center" colSpan={2}>
                          Total
                        </th>
                        <th className="text-center">
                          {countTotal(category, false)}
                        </th>
                        <th className="text-center">
                          {countTotal(category, true)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
