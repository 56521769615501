import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import Loading from "../../../components/Loading";
import {
  MDBBtn,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";

export default function Teams(props) {
  const { track } = useParams();
  const [user, setUser] = useState(props.user);
  const [teams, setTeams] = useState({});
  const [team, setTeam] = useState({});
  const [isInitializing, setIsInitializing] = useState(true);

  const [menu, setMenu] = useState(0);

  function fetchTeams() {
    setIsInitializing(true);
    return axios()
      .get(`/tracks/${track}/teams`)
      .then((response) => {
        setTeams(response.data.data);
        setIsInitializing(false);
      });
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  if (isInitializing) {
    return <Loading />;
  }

  if (menu === 1) {
    return <TeamDetails team={team} setTeam={setTeam} setMenu={setMenu} />;
  }

  return (
    <div className="home px-2 mt-3">
      <div className="row">
        <div className="col-md-2">
          <MDBBtn color="primary" href={"../"}>
            Back
          </MDBBtn>
        </div>
        <div className="col-md-8 text-center">
          <h2 style={{color:'#f93154', marginBottom:'20px'}}>Teams</h2>
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="">
        {teams.length ? (
          <MDBTable hover striped bordered>
            <MDBTableHead color="primary" textWhite>
              <tr style={{textAlign:'center',backgroundColor:'#111342',color:'#ffffff'}}>
                <th>Team ID</th>
                <th>Team Name</th>
                <th>Team Leader Name</th>
                {teams[0].track.type !== "professional" && (
                  <th>
                    {teams[0].track.type === "student_faculty"
                      ? "Institute Name"
                      : "Startup/Company/Organisation Name"}
                  </th>
                )}
                {teams[0].track.type !== "professional" &&
                  teams[0].track.type !== "startup" && (
                    <th>
                      {teams[0].track.type === "student_faculty"
                        ? "Institute State"
                        : "Company/Organisation State"}
                    </th>
                  )}
                <th>Members Count</th>
                <th>Mentors Count</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {teams.map((team, index) => (
                <tr key={index}>
                  <td>{team.id}</td>
                  <td>{team.name}</td>
                  <td>{team.leader.name}</td>
                  {team.track.type !== "professional" && (
                    <td>
                      {team.track.type === "student_faculty"
                        ? team.institute_name
                        : team.startup_company_msme_name}
                    </td>
                  )}
                  {team.track.type !== "professional" &&
                    team.track.type !== "startup" && (
                      <td>
                        {team.track.type === "student_faculty"
                          ? team.institute_state
                          : team.company_msme_state}
                      </td>
                    )}
                  <td>{team.members.length}</td>
                  <td>{team.mentors.length}</td>
                  <td>
                    <MDBBtn
                      onClick={() => {
                        setTeam(team);
                        setMenu(1);
                      }}
                      color={"primary"}
                    >
                      View details
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <div className="mt-3 text-center text-warning">
            No Registrations yet!
          </div>
        )}
      </div>
    </div>
  );

  function TeamDetails(props) {
    const team = props.team;

    return (
      <div className={"p-3"}>
        <div className="row">
        <div className="col-md-2">
          <MDBBtn
            color="primary"
            onClick={() => {
              props.setTeam({});
              props.setMenu(0);
            }}
          >
            Back
          </MDBBtn>
        </div>
        <div className="col-md-8 text-center">
          <h2 style={{color:'#f93154', marginBottom:'20px'}}>Team Members</h2>
        </div>
        <div className="col-md-2"></div>
      </div>

        <MDBTable bordered>
          <MDBTableHead>
            <tr style={{textAlign:'center',backgroundColor:'#111342',color:'#ffffff'}}>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Gender</th>
              <th scope="col">
                {team.category === "student_faculty"
                  ? "Enrollment Number"
                  : team.category === "startup"
                  ? "Role in Startup"
                  : "Job Title"}
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {team.members.map((member, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <th scope="col">{member.name}</th>
                <th scope="col">{member.email}</th>
                <th scope="col">{member.phone}</th>
                <th scope="col">{member.gender}</th>
                <th scope="col">
                  {team.category === "student_faculty"
                    ? member.enrollment_number
                    : team.category === "startup"
                    ? member.role_in_startup
                    : member.professional_employed_job_title}
                </th>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
        <br />
        <br />
        <div className={"row mb-3"}>
          <h5 className={"text-center col-sm"}>Team Mentors</h5>
        </div>
        {team.mentors.length ? (
          <MDBTable bordered>
            <MDBTableHead>
              <tr style={{textAlign:'center',backgroundColor:'#111342',color:'#ffffff'}}>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Gender</th>
                <th scope="col">Education Info</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {team.mentors.map((member, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <th scope="col">{member.name}</th>
                  <th scope="col">{member.email}</th>
                  <th scope="col">{member.phone}</th>
                  <th scope="col">{member.gender}</th>
                  <th scope="col">{member.education_info}</th>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <div className={"text-center text-warning"}>No mentors added yet</div>
        )}
      </div>
    );
  }
}
