import React from "react";

const Profile = (props) => {
  return (
    <div className="home px-2 pt-3">
      <h2 className="text-center">Profile</h2>
      <h4 className="mt-5 text-center ">
        Will be updated soon
      </h4>
    </div>
  );
};

export default Profile;
