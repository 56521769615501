import React from "react";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import auth from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { retrieve, store } from "../../utils/store";
import marquee from "react-fast-marquee";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (retrieve("challenge") > 0) {
      store("challenge", "");
      store("stage", "");
      window.location.href = window.location.href;
    }
  }, []);

  function handleLoginSubmit(e) {
    e.preventDefault();

    let data = new FormData(e.target);

    toast.promise(auth().login(data), {
      pending: {
        render() {
          setIsLoading(true);
          return "Signing in......";
        },
      },
      success: {
        render({ data }) {
          setIsLoading(false);
          auth().setToken(data.data);
          return "Logged in successfully";
        },
      },
      error: {
        render({ data }) {
          setIsLoading(false);
          let status = data.response.status;
          data = data.response.data;
          if (status === 404) {
            return "Email doesn't exist. Please register!";
          }
          if (status === 422) {
            return Object.values(data.errors)[0].toString();
          } else {
            return "Something went wrong!";
          }
        },
      },
    });
  }

  return (
    <div
      className="login-card"
      style={{
        position: "relative",
      }}
    >
    <div style={{
      position:'absolute',
      content:'',
      height:'670px',
      width:'100%',
      backgroundColor:'#000000',
      opacity:'0.1',
    }}></div>
      <MDBContainer fluid className="pb-4">
        <MDBRow className="row d-flex justify-content-center pb-4 mb-4">
          <MDBCol md="3" className="text-center">
            <div
              style={{
                minHeight: "508px",
                backgroundColor: "transparent",
                borderTopLeftRadius: "60px",
                borderBottomRightRadius: "60px",
                paddingTop: "50px",
              }}
              className=""
            >
              <img
                style={{
                  height: "365px",
                  width: "235px",
                  objectFit: "contain",
                }}
                className="img-fluid"
                src="./onion.png"
              />
              <div
                style={{
                  borderBottomRightRadius: "60px",
                  padding: "15px 0px",
                }}
              >
                <a target="_blank" href="https://doca.gov.in/goc">
                  <h5
                    className="linewp"
                    style={{
                      color: "#ffffff",
                      fontSize: "25px",
                      fontWeight: "700",
                      padding: "14px",
                      marginTop: "15px",
                      position: "relative",
                      backgroundColor: "#b16222",
                      width: "80%",
                      margin: "0 auto",
                      borderRadius: "5px",
                      borderTopLeftRadius: "30px",
                      borderBottomRightRadius: "30px",
                    }}
                  >
                    ONION CHALLENGE&nbsp;&nbsp;
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </h5>
                </a>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3" className="text-center">
            <div
              style={{
                minHeight: "508px",
                backgroundColor: "transparent",
                borderTopLeftRadius: "60px",
                borderBottomRightRadius: "60px",
                paddingTop: "50px",
              }}
              className=""
            >
              <img
                style={{
                  height: "380px",
                  width: "265px",
                  objectFit: "contain",
                }}
                className="img-fluid"
                src="./tomato.png"
              />

              <a target="_blank" href="https://doca.gov.in/gtc">
                <h5
                  className="linewp"
                  style={{
                    color: "#ffffff",
                    fontSize: "25px",
                    fontWeight: "700",
                    padding: "14px",
                    marginTop: "15px",
                    position: "relative",
                    backgroundColor: "#d92a49",
                    width: "80%",
                    margin: "0 auto",
                    borderRadius: "5px",
                    borderTopLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                >
                  TOMATO CHALLENGE &nbsp;&nbsp;
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </h5>
              </a>
            </div>
          </MDBCol>
          <MDBCol md="3" className="text-center">
            <h5
              style={{
                backgroundColor: "#8722af",
                color: "#fff",
                fontSize: "25px",
                fontWeight: "700",
                padding: "14px",
                marginTop: "70px",
                borderTopLeftRadius: "60px",
                marginBottom: "0",
              }}
            >
              LOGIN
            </h5>
            <MDBCard
              className=""
              style={{
                borderBottomRightRadius: "60px",
              }}
            >
              <MDBCardBody
                className="shadow"
                style={{
                  borderBottomRightRadius: "60px",
                }}
              >
                {/*<h5 className="text-center mb-4 blink">*/}
                {/*  RESULTS FOR ROUND 1 ANNOUNCED ! <br />*/}
                {/*  <a*/}
                {/*    href="https://doca.gov.in/goc/theme/document/OGC_Results.pdf"*/}
                {/*    className="text-center"*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    {" "}*/}
                {/*    Check Now{" "}*/}
                {/*  </a>*/}
                {/*</h5>*/}

                <h3 className="text-center mb-4">Welcome to Grand Challenge</h3>
                <form onSubmit={handleLoginSubmit}>
                  <h6>Email:</h6>
                  <MDBInput
                    required={true}
                    icon="envelope"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    name="email"
                  />
                  <br />
                  <h6>Password:</h6>
                  <MDBInput
                    required={true}
                    icon="password"
                    group
                    type="password"
                    validate
                    error="wrong"
                    success="right"
                    name="password"
                  />
                  <div className="text-end mt-2">
                    <a
                      href="/reset"
                      className="mt-2 text-danger link hover:text-dark"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className="text-center mt-3">
                    <MDBBtn
                      className="shadow"
                      color="danger"
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? "Signing in...." : "Sign in"}
                    </MDBBtn>

                    <div className="d-flex flex-row justify-content-center gap-1 fs-8 mt-4">
                      <p className="fw-light">Don't have an Account?</p>
                      <a href={"/register"} className="text-danger link">
                        Sign Up
                      </a>

                      {/*<a href={"/spocregister"} className="text-danger link">*/}
                      {/*  Register for SPOC*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="3" className="text-center">
            <h5
              style={{
                backgroundColor: "#8722af",
                color: "#fff",
                fontSize: "25px",
                fontWeight: "700",
                padding: "14px",
                marginTop: "70px",
                borderTopLeftRadius: "60px",
                marginBottom: "0",
              }}
            >
              Announcement
            </h5>
            <MDBCard
              className=""
              style={{
                borderBottomRightRadius: "60px",
              }}
            >
              <MDBCardBody
                className="shadow shadowOuter"
                style={{
                  borderBottomRightRadius: "60px",
                }}
              >
              <div style={{
                height:'410px',
                width:'100%',
                textAlign:'left',

              }}>
               
                  <p>
                    <a style={{
                      color:'#000000',
                      fontSize:'15px',
                    }} 
                    href="https://doca.gov.in/goc/assets/document/Final-Results-Stage-1.pdf" target="_blank">
                    <strong>Onion Grand Challenge:</strong> Result announcement (Stage - I)
                    </a>
                  </p>
                  <p>
                    <a style={{
                      color:'#000000',
                      fontSize:'15px',
                    }} 
                    href="javascript:void(0)">
                    <strong>Onion Grand Challenge:</strong> Result announcement (Stage - II)
                    </a>
                  </p>
                  <p>
                    <a style={{
                      color:'#000000',
                      fontSize:'15px',
                    }}
                    href="javascript:void(0)">
                    <strong>Tomato Grand Challenge:</strong> Deadline for Idea Submission is <span class="blink">September 30th, 2023</span>
                    </a>
                  </p>
              </div>      
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
