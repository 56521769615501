import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import requireAuth from "../../utils/requireAuth";
import RouteHelper from "../../utils/routeHelper";
import axios from "../../utils/axios";
import Loading from "../../components/Loading";
import { Route, Routes } from "react-router-dom";
import Base from "../../components/Team/Manage/Base";
import { retrieve } from "../../utils/store";

export const MyTeam = (props) => {
  const [user, setUser] = useState(props.user);
  const [team, setTeam] = useState({});
  const [isInitializing, setIsInitializing] = useState(true);

  const challege = user.current_challenge;

  const [menu, setMenu] = useState(0); // 0 => Table 1 => Member 2 => Mentor
  const [currentData, setCurrentData] = useState({}); //Used for passing current member/mentor data into edit form

  function fetchTeam() {
    setIsInitializing(true);
    return axios()
      .get("challenges/team")
      .then((response) => {
        setTeam(response.data.data);
        setIsInitializing(false);
      });
  }

  useEffect(() => {
    fetchTeam();
  }, []);

  function handleDelete(category, id) {
    setIsInitializing(true);
    axios()
      .post(`challenges/team/delete/${id}`, { category })
      .then((response) => {
        setTeam(response.data.data);
        setIsInitializing(false);
      });
  }

  if (isInitializing) {
    return <Loading />;
  }

  if (menu === 1) {
    return (
      <Base
        data={currentData}
        category={team.category}
        setMenu={setMenu}
        setTeam={setTeam}
      />
    );
  }

  if (menu === 2) {
    return (
      <Base
        data={currentData}
        category={"mentor"}
        setMenu={setMenu}
        setTeam={setTeam}
      />
    );
  }

  return (
    <Routes>
      {" "}
      <Route
        path="/"
        element={
          <div className="home px-2 mt-3">
            <h2 style={{
              fontSize:"1.50rem",
              padding:"15px 0px",
              backgroundColor:"rgb(39, 44, 147)",
              color:"#ffffff",
              marginBottom:"15px"
            }}
            className="text-center"
            >
            Team Details</h2>
            <div className="">
              <MDBTable hover striped bordered>
                <MDBTableHead color="primary" textWhite>
                  <tr>
                    <th>Track</th>
                    <th>Team ID</th>
                    <th>Team Name</th>
                    <th>Team Leader Name</th>
                    <th>Members Count</th>
                    <th>Mentors Count</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>{team.track_name} Track</td>
                    <td>{team.id}</td>
                    <td>{team.name}</td>
                    <td>{team.leader.name}</td>
                    <td>{team.members.length}</td>
                    <td>{team.mentors.length}</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        }
      />
      <Route
        path="/members"
        element={
          <Members
            sideBarOpen={props.sideBarOpen}
            members={team.members}
            team={team}
            setMenu={setMenu}
            setCurrentData={setCurrentData}
            handleDelete={handleDelete}
            user={user}
          />
        }
      />
      <Route
        path="/mentors"
        element={
          <Mentors
            user={user}
            sideBarOpen={props.sideBarOpen}
            mentors={team.mentors}
            team={team}
            setMenu={setMenu}
            setCurrentData={setCurrentData}
            handleDelete={handleDelete}
          />
        }
      />
    </Routes>
  );
};

export const Members = (props) => {
  return (
    <div className="mt-3 px-2">
      <div className={"row mb-3"}>
        <h2 style={{
              fontSize:"1.50rem",
              padding:"15px 0px",
              backgroundColor:"rgb(39, 44, 147)",
              color:"#ffffff",
              marginBottom:"15px"
            }}
            className="text-center">
        Team Members</h2>

        <div className={"col-sm"}></div>
        <div className={"col-sm d-flex justify-content-end"}>
          {props.team.members.length >= 6 ||
          !props.user.current_challenge.registration_open ? (
            <div></div>
          ) : (
            <MDBBtn
              color={"success"}
              onClick={() => {
                props.setMenu(1);
                props.setCurrentData({});
              }}
            >
              Add Member
            </MDBBtn>
          )}
        </div>
        <h7 className={"mx-3"}>
          Team Size: Minimum 1 - Maximum 6 members ( Including team leader )
        </h7>
      </div>

      <MDBTable hover striped bordered>
        <MDBTableHead color="primary-color" textWhite>
          <tr>
            <th>Member Role</th>
            <th>Member Name</th>
            <th>Member Email</th>
            <th>Member Phone Number</th>
            <th>Member Gender</th>
            <th scope="col">
              {props.team.category === "student_faculty"
                ? "Enrollment Number"
                : props.team.category === "startup"
                ? "Role in Startup"
                : "Job Title"}
            </th>
            <th>Action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {props.members.map((member, index) => (
            <tr key={index}>
              <td>{member.role === "leader" ? "Leader" : "Member"}</td>
              <td>{member.name}</td>
              <td>{member.email}</td>
              <td>{member.phone}</td>
              <td>{member.gender}</td>
              <td>
                {props.team.category === "student_faculty"
                  ? member.enrollment_number
                  : ["startup", "company", "msme"].includes(props.team.category)
                  ? member.role_in_startup_company_msme
                  : member.professional_employed
                  ? member.professional_employed_job_title
                  : "Not Employed"}
              </td>
              <td>
                <div className="flex row gap-2 ">
                  <MDBBtn
                    color="primary"
                    className="col p-2"
                    onClick={() => {
                      props.setMenu(1);
                      props.setCurrentData(member);
                    }}
                    disabled={!props.user.current_challenge.registration_open}
                  >
                    Update
                  </MDBBtn>
                  {member.role === "leader" ? (
                    <div></div>
                  ) : (
                    <MDBBtn
                      disabled={!props.user.current_challenge.registration_open}
                      color="danger"
                      className="col p-2"
                      onClick={() => {
                        props.handleDelete("member", member.id);
                      }}
                    >
                      Delete
                    </MDBBtn>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export const Mentors = (props) => {
  return (
    <div className="px-3 mt-3">
      <div className={"row mb-3"}>
        <h2 style={{
              fontSize:"1.50rem",
              padding:"15px 0px",
              backgroundColor:"rgb(39, 44, 147)",
              color:"#ffffff",
              marginBottom:"15px"
            }}
            className={"ml-3 col-md-12 text-center"}>
        Team Mentors</h2>
        <div className={"col-sm"}></div>
        <div className={"col-sm d-flex justify-content-end"}>
          {props.team.mentors.length >= 2 ||
          !props.user.current_challenge.registration_open ? (
            <div></div>
          ) : (
            <MDBBtn
              color={"success"}
              onClick={() => {
                props.setMenu(2);
                props.setCurrentData({});
              }}
            >
              Add Mentor
            </MDBBtn>
          )}
        </div>
        <h7 className={"mx-1"}>
          Upto 2 mentors can be added. Team can also participate without
          mentors.
        </h7>
      </div>
      {props.team.mentors.length ? (
        <MDBTable hover striped bordered>
          <MDBTableHead color="primary-color" textWhite>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Gender</th>
              <th scope="col">Education Info</th>
              <th scope="col">Action</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {props.mentors.map((member, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <th scope="col">{member.name}</th>
                <th scope="col">{member.email}</th>
                <th scope="col">{member.phone}</th>
                <th scope="col">{member.gender}</th>
                <th scope="col">{member.education_info}</th>
                <th scope="col">
                  <div className="flex row gap-2 ">
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      disabled={!props.user.current_challenge.registration_open}
                      onClick={() => {
                        props.setMenu(2);
                        props.setCurrentData(member);
                      }}
                    >
                      Update
                    </MDBBtn>
                    {member.role === "leader" ? (
                      <div></div>
                    ) : (
                      <MDBBtn
                        disabled={
                          !props.user.current_challenge.registration_open
                        }
                        color="danger"
                        className="col p-2"
                        onClick={() => {
                          props.handleDelete("mentor", member.id);
                        }}
                      >
                        Delete
                      </MDBBtn>
                    )}
                  </div>
                </th>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      ) : (
        <h4 className={"text-center text-warning mb-4"}>
          No mentors added yet
        </h4>
      )}
    </div>
  );
};
