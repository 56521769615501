
import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, 
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import ProblemStatementsPage from "../../components/Admin/ProblemStatement";
import Loading from "../../components/Loading";
import axios from "../../utils/axios";
import { confirm } from "react-confirm-box";

const ProblemStatementsSubmittedSPOC = (props) => {
  const [currentData, setCurrentData] = useState({});
  const [track, setTrack] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [problems, setProblems] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [trs,setTr] = useState([]);
  const [showForm,setShowForm] = useState(false)
  const [upd,setUpd] = useState(false);
  const [ids,setId] = useState(0)
  const [category,setCategory] = useState('')
  const [title,setTitle] = useState('')
  const [description,setDescription] = useState('')
  const [guidelines,setGuidelines] = useState('')
  const [datasheet,setDatasheet] = useState('')
  const [currentPs, setCurrentPs] = useState(false);
  const [vidurl,setvidurl] = useState("");
  const [theme,setTheme] = useState("");
  const [cat,setCat] = useState('')
   const [org,setOrg] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orgs, setOrgs] = useState([]);

 // const [track,setTrack] = useState([]);
 const arr = new Array()
  const [val,setVal] = useState({
  category : '',
   title : '',
   description : '',
   guidelines_file :[],
   datasheet_file : [],
   video_url : '',
   theme : ''
   })

  useEffect(() => {
    axios()
    .get("/Organizations")
    .then((response) => {
      setOrgs(response.data.data);
      console.log(response.data.data);
      setIsLoading(false);
    });

    axios()
      .get("/problems")
      .then((response) => {
        setProblems(response.data.data);
        console.log(response.data.data);
        setIsLoading(false);
        
      });


      axios()
      .get("/tracks")
      .then((response) => {
        setTracks(response.data.data);     
        setIsLoading(false);
        
      });

  }, []);

  if (isLoading) {
    return <Loading />;
  }


  function handleSubmit(e){
    e.preventDefault();
    
    if(val.category === '')
    {
      val.category = category
      console.log(val.category,category)
    }
    if(val.title === '')
    {
      val.title = title
    }
    if(val.description === '')
    {
      val.description = description
    }
    if(val.video_url === '')
    {
      val.video_url = vidurl
    }
    if(val.theme === '')
    {
      val.theme = theme
    }
    if(val.description === '')
    {
      val.description = description
    }
    if(val.guidelines_file.length === 0)
    {
      val.guidelines_file[0] = guidelines
    }
    if(val.datasheet_file.length === 0)
    {
      val.datasheet_file[0] = datasheet
    }
 
    const data = new FormData(e.target);
    console.log(data);
    // data.append("category",val.category);
    // data.append("title",val.title);
    // data.append("description",val.description);
    // data.append("guidelines_file",val.guidelines_file);
    // data.append("datasheet_file",val.datasheet_file);
    // data.append("video_url",val.video_url);
    // data.append("theme",val.theme);
    console.log(data);
    toast.promise(
      axios().post(`/problems/${ids}`, data),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {
                  
            setIsSubmitting(false);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
 
              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
       
      }
    );
  }
  

  const handleUpd = (prob) => {
  setUpd(!upd);
  setId(prob.id)
  setCategory(prob.category)
  setTitle(prob.title)
  setDescription(prob.description)
  setTr(prob.tracks.data)
  setvidurl(prob.video_url)
  setTheme(prob.theme)
  console.log(prob.theme)
 
 console.log(`Problem tracks = ${prob}`)
  }

  const handleDelete = async (id) => {

           {/*
             Problem Statement Delete part
        */ }
  
 if(await confirm("Are you sure you want to delete this Problem Statement?")) {    

        toast.promise(
          axios().post(`/problemsDelete/${id}`),
          {
            pending: {
              render() {
                setIsSubmitting(true);
                return "Loading....";
              },
            },
            success: {
              render() {
                      axios()
                            .get("/problems")
                            .then((response) => {
                              setProblems(response.data.data);
                              setIsLoading(false);
                            });
                setIsSubmitting(false);
                return "Deleted successfully!";
              },
            },
            error: {
              render({ data }) {
                setIsSubmitting(false);
                let status = data.response.status;
                data = data.response.data;
                if (status === 422) {
                  Object.entries(data.errors).forEach(([key, value]) => {
                    toast.error(value.toString(), {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
    
                  return "There were errors in some fields";
                } else {
                  return "Something went wrong!";
                }
              },
            },
           
          }
        );
    }
  }

  return ( 
    <div
      className="px-2 pt-3"
      style={{ marginLeft: props.sideBarOpen ? "260px" : "20px" }}
    >
      
      <div className="">
       
      { upd ? <><MDBBtn color="primary" className="col p-2" onClick = {handleUpd}>
		  
      Back
   
</MDBBtn> 

{problems.map((problem, id) => ids === problem.id && <MDBContainer className="mt-4 px-2 d-flex align-center justify-content-center" key = {problem.id}>
{console.log(` Ids == ${ids,problem.id}`) }
<MDBRow className="row d-flex justify-content-center">
          <MDBCol md="10">
            <MDBCard className="mt-4">
              <MDBCardBody className="shadow">
              <form onSubmit = {handleSubmit}>
                  <div>
                    <div className="text-dark">
                      <h6 className="mb-2 pb-1 fw-bold">Category </h6>
                      <div>
                      <select
                        required={true}
                        className="form-control form-select"
                        name= "category"
                        label="Category"
                        defaultValue={problem.category}
                        
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      >

                         <option value="">Choose the category</option>
                        <option value="sw">Software</option>
                        <option value="hw">Hardware</option>
                        
                      </select>
                    </div>
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Title </h6>
                      <MDBInput
                        required={true}
                        label="Title"
                        defaultValue={problem.title}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        name="title"
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Description </h6>
                      <MDBInput type="textarea" name="description" label="Description" rows="5" 
                      defaultValue={problem.description} 
                      onSubmit = {(e) => e.target.value === '' && setVal({...val, [e.target.name] : problem.description })}
                      onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Upload Guidelines file </h6>
                      <MDBInput
                  group
                  type="file"
                  validate
                  error="wrong"
                  success="right"
                  name={"guidelines_file"}
                  accept={"application/pdf"}
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.files[0] })}
         
                />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Upload Datasheets file </h6>
                      <MDBInput
                  group
                  type="file"
                  validate
                  error="wrong"

                  success="right"
                  name={"datasheet_file"}
                  accept={"application/pdf"}
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.files[0] })}

                  required={false}
                />
                    <br></br>

                    <h6 className="mb-2 pb-1 fw-bold">Enter Video URL </h6>
                      <MDBInput
                  group
                  type="text"
                  validate
                  error="wrong"
                  defaultValue={problem.video_url}
                  success="right"
                  name="video_url"
                  
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                />


                    <br></br>

                    <h6 className="mb-2 pb-1 fw-bold">Theme </h6>
                      <MDBInput
                  group
                  type="text"
                  validate
                  error="wrong"
                  defaultValue={problem.theme}
                  success="right"
                  name="theme"
                 
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                
                />


                    <br></br>

                    
                    {
                    Object.entries(tracks).map(([category_name, tracks]) => <div className="d-flex flex-column mt-4" key={category_name}>
                      {tracks.map((track, id) =>  < >
                     { 
                   <div  style = {{display : "flex" , flexDirection : "row"}}>
                      <p className = "mr-3"> {track.name} </p>
                    <input class="form-check-input" value = {track.id}  defaultChecked = { (() => { console.log("hello"); let is_checked = false; Object.entries(trs).map(([category_name,tra]) => tra.map((tr) => {if(track.id === tr.id) {is_checked = true; console.log(track.id, tr.id); } console.log(track.id,tr.id)})); return is_checked})() } name = "tracks[]" type="checkbox" id="checkboxNoLabel" varia-label="..." />
                   
                  </div>
}
                 
                    
                   </>)  }
                   
                   </div>
                   ) }
                   

                   </div>

                  
                
                    
                  <div className="text-center py-3">
                    <MDBBtn
                      className="shadow"
                      color="danger"
                      
                      type="submit"
                      
                    >
                     Submit
                    </MDBBtn>
                  </div>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

  </MDBContainer>)}
  <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  

 </>: <> 

 {showForm ?<> <MDBBtn color="primary" className="col p-2" onClick = {() => setShowForm(!showForm)}>
		  
      Back   
</MDBBtn> <ProblemStatementsPage setProblems={setProblems} /> </> : <>
        <div className={" justify-content-end text-right mr-4"}>
           
            <MDBBtn
              className={"mx-12 mb-3"}
              color={"success"}
              onClick={ () => setShowForm(!showForm)}
            >
              Add Problem Statements
            </MDBBtn>
          
        </div>
      <h2 className="mx-3">Problem Statements</h2>

      <select
                        required={true}
                        className="form-control form-select mb-3"
                        name= "category"
                        label="Category"              
                        onChange = {(e) => setCat(e.target.value)} 
                      >
                         <option value="">Choose the category</option>
                        <option value="sw">Software</option>
                        <option value="hw">Hardware</option>
                        
                      </select>

                      <select
                        required={true}
                        className="form-control form-select mt-4 mb-4"
                        name= "Organization"
                        label="Organization"              
                        onChange = {(e) => setOrg(e.target.value)} 
                      >
                         <option value="">Choose Organization</option>
                        {Array.from(new Set(problems.map((j) => j.organization.name))).map((org) => (
          <option value={org}>{org}</option>
        ))}
                        
                      </select>

                      
                      <select
                        required={true}
                        className="form-control form-select mt-4 mb-4"
                        name= "Theme"
                        label="Theme"              
                        onChange = {(e) => setTheme(e.target.value)} 
                      >
                         <option value="">Choose Theme</option>
                         <option value="MQ==">Agriculture, FoodTech &amp; Rural Development </option>
                          <option value="Mg==">Blockchain &amp; Cybersecurity </option>
                          <option value="Mw==">Clean &amp; Green Technology </option>
                          <option value="NA==">Fitness &amp; Sports</option>
                          <option value="NQ==">Heritage &amp; Culture </option>
                          <option value="Ng==">MedTech / BioTech / HealthTech </option>
                          <option value="Nw==">Miscellaneous </option>
                          <option value="OA==">Renewable / Sustainable Energy </option>
                          <option value="OQ==">Robotics and Drones </option>
                          <option value="MTA=">Smart Automation </option>
                          <option value="MTE=">Smart Vehicles </option>
                          <option value="MTI=">Travel &amp; Tourism </option>
                          <option value="MTM=">Transportation &amp; Logistics </option>
                          <option value="MTQ=">Disaster Management</option>
                          <option value="MTU=" selected="">Smart Education</option>
                        
                      </select>
                      
      <MDBTable hover striped bordered>
          <MDBTableHead color="primary" textWhite>
            <tr>
              <th>Sl.No</th>
              <th>Category</th>
              <th>Title</th>
              <th>Description</th>
              <th>Organization</th>
              <th>Guidelines file</th>
              <th>Datasheet file</th>
              <th>Video Url</th>
              <th>Theme</th>
              <th>Action</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
                     {/* Displaying the values based on filter */}

                     
          {problems.map((problem, index) => {
           
           return (
             (
              ( cat === "" && org === "" || cat === problem.category && org === "" || cat === "" && org === problem.organization.name ) ?
             
             <tr key={index}>
               <th scope="row">{index + 1}</th>
               <td>{problem.category == "sw" ? "Software":"Hardware"}</td>
               <td>{problem.title}</td>                   
               <td>{problem.description}</td>
               <td>{problem.organization.name}</td>
               {console.log(problem.organization.name,org)}
               

 <td><MDBBtn color = "primary" href = {problem.guideline_file}>View Guidelines</MDBBtn></td>
          <td><MDBBtn color = "primary" href = {problem.datasheet_file}>View Datasheet</MDBBtn></td>

 <td>
   
                 {problem.video_url ? (
                  <MDBBtn

                  color="primary"
                  className="col p-2"
                 
                  >
                   <a
                     style = {{color : "white"}}
                     href={problem.video_url}
                     target = "blank"
                   >
                     View Video
                   </a>
                   </MDBBtn>
                 ) : (
                   "Not Submitted"
                 )}
   </td>

   <td>Theme 1</td>

 <td>
   <div className="flex row gap-2 ">
 
   <MDBBtn color="primary" className="col p-2" onClick = {() => handleUpd(problem)}>
   
         Update
       
   </MDBBtn>
   
   <MDBBtn color="danger" className="col p-2" onClick = {(e) => handleDelete(problem.id)}>
         Delete
   </MDBBtn>
   </div>
 </td>
             </tr> : (problem.category === cat && problem.organization.name === org) &&
             <tr key={index}>
               <th scope="row">{index + 1}</th>
               <td>{problem.category}</td>
               <td>{problem.title}</td>
               <td>{problem.description}</td>
               <td>{problem.organization.name}</td>
               <td><MDBBtn color = "primary" href = {problem.guidelines_file}>View Guidelines</MDBBtn></td>
 <td><MDBBtn color = "primary" href = {problem.guidelines_file}>View Datasheet</MDBBtn></td>
   {/* Video URL */}
 <td>
   
   {problem.videourl ? (
     <MDBBtn
       color="primary"
       className="col p-2"
       href={problem.videourl}
     >
       View Video
     </MDBBtn>
   ) : (
     "Not Submitted"
   )}
</td>
      <td>Blockchain &amp; Cybersecurity</td>
          <td>
            <div className="flex row gap-2 ">
          
            <MDBBtn color="primary" className="col p-2" onClick = {() => handleUpd(problem)}>
            
           <h5> <AiFillEdit /> </h5>
                
            </MDBBtn>
            
            <MDBBtn color="danger" className="col p-2" onClick = {(e) => handleDelete(problem.id)}>
            <h5><AiOutlineDelete /> </h5>
            </MDBBtn>
			      </div>
		      </td>
                      </tr>
                    ));
                  })}

 
          
       
          </MDBTableBody>
        </MDBTable>
        
        </>   }</>
        
                }
      
      </div>
      
    </div>
  );
}

export default ProblemStatementsSubmittedSPOC;
