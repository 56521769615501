import React, { useEffect, useState } from "react";

export default function Footer() {
  return (
    <footer className="">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10 offset-md-2 text-center">
            <div className="footer-content">
              <p>
                Website Content Managed by &nbsp; Ministry of Consumer Affairs,
                Food &amp; Public Distribution, Government of India
                <br />
                <span>
                  Designed, Developed and Hosted by&nbsp;
                  <a
                    target="_blank"
                    title="NIC, External Link that opens in a new window"
                    href="https://www.mic.gov.in/"
                  >
                    <strong>Ministry of Education Innovation Cell</strong>
                  </a>
                  <strong> ( MIC )</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
