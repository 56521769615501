import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBModalBody,
} from "mdb-react-ui-kit";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "./../../utils/axios";

const Stage2Form = (props) => {
  async function handleSubmit(e) {
    e.preventDefault();
    let data = new FormData(e.target);

    toast.promise(
      axios().post(`submission/round2/${props.submission.id}`, data),
      {
        pending: {
          render() {
            return "Submitting....";
          },
        },
        success: {
          render() {
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
      }
    );
  }

  return (
    <MDBModalBody>
      <MDBRow className="row d-flex justify-content-center">
        <div className="justify-content-left">
          <MDBBtn color="primary" onClick={() => props.setCurrentSub(false)}>
            Back
          </MDBBtn>
        </div>
        <MDBCol md="6">
          <h3 className="text-center text-danger mb-3">ROUND 2 SUBMISSION</h3>
          <h6 className="mb-2 pb-1 fw-bold mb-4">
            Guidelines for formulating the proposals &nbsp;&nbsp;
            <a
              href={"/presentation_template_TGC.pptx"}
              target="_blank"
              className="text-white btn btn-danger link"
            >
              Download
            </a>
          </h6>

          <form onSubmit={handleSubmit}>
            <h6 className="mb-2 pb-1 fw-bold mb-4">
              Submit your research proposal:{" "}
              <span style={{ color: "red", fontSize: "14px" }}>
                (Max Size : 10MB , PDF only)
              </span>
            </h6>
            <MDBInput
              group
              type="file"
              validate
              error="wrong"
              success="right"
              name={"file"}
              accept={"application/pdf"}
              className="mb-4"
            />
            <div className="d-flex flex-row justify-content-center gap-1 fs-8 mt-4">
              <MDBBtn className="shadow" color="danger" type="submit">
                Submit
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MDBModalBody>
  );
};

export default Stage2Form;
