import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar";
import { SidebarData as SpocSideBarData } from "../../components/Evaluator/Sidebar/SidebarData";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useEffect, useState } from "react";
import requireAuth from "../../utils/requireAuth";
import RouteHelper from "../../utils/routeHelper";
import Loading from "../../components/Loading";
import Dashboard from "./Dashboard";
import Evaluations from "./Evaluations";
import { useMediaQuery } from "react-responsive";

export default function Base() {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    requireAuth().then((data) => {
      setUser(data.user);

      if (data.user.role !== "evaluator") {
        return RouteHelper.redirectToRoleDashboard(data.user.challenge_role);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header
        isSideBarPresent={true}
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
      />
      <div className="row" style={{ marginRight: "0px" }}>
        {(isMobile ? showSideBar : true) && (
          <SideBar
            sideBarData={SpocSideBarData}
            className="col-sm-3 col-md-2"
            isSideBarPresent={true}
            setShowSideBar={setShowSideBar}
            showSideBar={showSideBar}
          />
        )}
        <div
          className="col-lg-10 offset-lg-2 col-md-9 offset-md-3 col-8"
          style={{ minHeight: "75.4vh" }}
        >
          <Routes>
            <Route path="/" element={<Dashboard user={user} />} />
            <Route path="evaluate" element={<Evaluations user={user} />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}
