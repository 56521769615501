import React from "react";
import { useState } from "react";
import RouteHelper from "../../utils/routeHelper";
import { useEffect } from "react";
import requireAuth from "../../utils/requireAuth";
import Loading from "../../components/Loading";
import { setChallenge } from "../../utils/auth";
import { store } from "../../utils/store";
import Header from "../../components/Header/Header";

function Challenges(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    requireAuth().then((data) => {
      if (data.user.challenge_role !== "spoc") {
        // return RouteHelper.redirectToRoleDashboard(data.user.challenge_role);
      }

      setUser(data.user);

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Header isSideBarPresent={true} />
      <div className="row" style={{ marginRight: "0px" }}>
        <div>
          <div class="container">
            <div class="row justify-content-center">
              {user.challenges.map((challenge, index) => (
                <div class="col-md-6" key={index}>
                  <div class="card outCard m-4">
                    <div
                      class="card-body text-white pl-0 pr-0"
                      style={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        paddingBottom: "0",
                      }}
                    >
                      <h5 class="card-title text-center mb-2">
                        {challenge.name.toUpperCase()}
                      </h5>
                      <img
                        style={{
                          height: "150px",
                          width: "150px",
                          objectFit: "contain",
                        }}
                        className="img-fluid"
                        src={`\\${challenge.image}`}
                        alt="Challenge Image"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "0px!important",
                              width: "100%",
                              backgroundColor: "#272c93",
                              color: "#ffffff",
                            }}
                            onClick={() => {
                              store("challenge", challenge.id);
                              RouteHelper.redirect("/spoc");
                            }}
                            className="btn btnStage rounded-0"
                          >
                            Select
                          </button>
                        </div>
                        <div
                          style={{
                            width: "50%",
                          }}
                        >
                          {challenge.details && (
                            <p
                              style={{
                                marginBottom: "0",
                              }}
                            >
                              <a
                                style={{
                                  fontSize: "16px",
                                  borderRadius: "0px!important",
                                  width: "100%",
                                }}
                                className="btn btn-warning rounded-0"
                                target="_blank"
                                href={challenge.link}
                              >
                                {challenge.details}
                              </a>{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Challenges;
