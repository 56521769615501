import React from "react";
import { useState } from "react";
import { setChallenge } from "../../../utils/auth";

function Challenges(props) {
  const [user, setUser] = useState(props.user);

  return (
    <div>
      <div class="container">
        <div class="row justify-content-center">
          {user.challenges.map((challenge, index) => (
            <div class="col-md-6" key={index}>
              <div class="card outCard m-4">
                <div
                  class="card-body text-white pl-0 pr-0"
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    paddingBottom: "0",
                  }}
                >
                  <h5 class="card-title text-center mb-2">
                    {challenge.name.toUpperCase()}
                  </h5>
                  <img
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                    className="img-fluid"
                    src={`\\${challenge.image}`}
                    alt="Challenge Image"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      <button
                        style={{
                          borderRadius: "0px!important",
                          width: "100%",
                          backgroundColor: "#272c93",
                          color: "#ffffff",
                          marginBottom: "2rem",
                        }}
                        onClick={() =>
                          setChallenge(challenge.id, true, true, user)
                        }
                        className="btn btnStage rounded-0"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Challenges;
