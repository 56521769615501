import React from "react";

import {
  FaUsers,
  FaUser,
  FaUserTie,
  FaLightbulb,
  FaPhone,
  FaUserCircle,
  FaChessKing,
  FaStepForward,
} from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { BsPatchQuestionFill } from "react-icons/bs";
import { IconName } from "react-icons/fa";

export const SidebarData = [
  {
    title: "Grand Challenges",
    path: "/challenges",
    icon: <FaChessKing />,
  },
  {
    title: "My Team",
    path: "my-team",
    icon: <FaUsers />,
    iconClosed: <RiArrowDownSFill />,
    iconOpened: <RiArrowUpSFill />,

    subNav: [
      {
        title: "Members",
        path: "my-team/members",
        icon: <FaUser />,
      },
      {
        title: "Mentors",
        path: "my-team/mentors",
        icon: <FaUserTie />,
      },
    ],
  },
  {
    title: "Idea Submission",
    path: "idea-submission/problem-statements",
    icon: <FaLightbulb />,
    iconClosed: <RiArrowDownSFill />,
    iconOpened: <RiArrowUpSFill />,

    subNav: [
      // {
      //   title: "Verticals",
      //   path: "idea-submission/problem-statements",
      //   icon: <BsPatchQuestionFill />,
      // },
      {
        title: "View Submissions",
        path: "idea-submission/submitted-ideas",
        icon: <HiLightBulb />,
      },
    ],
  },
  {
    title: "Stages",
    path: "/team/stages",
    icon: <FaStepForward />,
  },
  {
    title: "Contact Us",
    path: "contact",
    icon: <FaPhone />,
  },

  // {
  //   title: "Profile",
  //   path: "profile",
  //   icon: <FaUserCircle />,
  // },
];
