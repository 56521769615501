import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import Loading from "../../../components/Loading";
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";

export default function Submissions(props) {
  const { track } = useParams();
  const [user, setUser] = useState(props.user);
  const [submissions, setSubmissions] = useState({});
  const [isInitializing, setIsInitializing] = useState(true);

  function fetchSubmissions() {
    setIsInitializing(true);
    return axios()
      .get(`/tracks/${track}/submissions`)
      .then((response) => {
        setSubmissions(response.data.data);
        setIsInitializing(false);
      });
  }

  useEffect(() => {
    fetchSubmissions();
  }, []);

  if (isInitializing) {
    return <Loading />;
  }

  return (
    <div className="home px-2 mt-3">
      <div className="row">
        <div className="col-md-2">
          <MDBBtn color="primary" href={"../"}>
            Back
          </MDBBtn>
        </div>
        <div className="col-md-8 text-center">
          <h2>Submissions123</h2>
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="">
        <MDBTable hover striped bordered>
          <MDBTableHead color="primary" textWhite>
            <tr style={{textAlign:'center',backgroundColor:'#111342',color:'#ffffff'}}>
              <th>Submission ID</th>
              <th>Idea Title</th>
              <th>Idea Document</th>
              <th>Other Document</th>
              <th>Submitted At</th>
              <th>Vertical Title</th>
              <th>Team ID</th>
              <th>Team Name</th>
              <th>Team Leader Email</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {submissions.map((submission, index) => (
              <tr key={index}>
                <td>{submission.id}</td>
                <td>{submission.title}</td>
                <td>
                  {submission.idea_document_file ? (
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      href={submission.idea_document_file}
                    >
                      View Idea Document
                    </MDBBtn>
                  ) : (
                    "Not Submitted"
                  )}
                </td>
                <td>
                  {submission.other_document_file ? (
                    <MDBBtn
                      color="primary"
                      className="col p-2"
                      href={submission.other_document_file}
                    >
                      View Other Document
                    </MDBBtn>
                  ) : (
                    "Not Submitted"
                  )}
                </td>
                <td>{submission.created_at}</td>
                <td>{submission.problem_statement.title}</td>
                <td>{submission.team.id}</td>
                <td>{submission.team.name}</td>
                <td>{submission.team.leader.email}</td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
}
