import React, { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import Loading from "../../../components/Loading";
import IdeaSubmission from "../../../components/IdeaSubmission";
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { setChallenge } from "../../../utils/auth";
import RouteHelper from "../../../utils/routeHelper";
import { store } from "../../../utils/store";

export const Teams = (props) => {
  const [user, setUser] = useState(props.user);
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [currentSub, setCurrentSub] = useState(false);

  useEffect(() => {
    axios()
      .get("/teams")
      .then((response) => {
        setSubmissions(response.data.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (currentSub !== false) {
    return (
      <IdeaSubmission
        setCurrentPs={setCurrentSub}
        problem={submissions[currentSub].problem_statement}
        setFetchProblems={(a) => a}
        idea={submissions[currentSub]}
      />
    );
  }

  return (
    <div className="home px-2 pt-3">
      <h2  style={{
        fontSize:"1.50rem",
        padding:"15px 0px",
        backgroundColor:"rgb(39, 44, 147)",
        color:"#ffffff",
        marginBottom:"15px"
      }}
      className="text-center"
      >
      Your Teams</h2>
      <input style={{
        float:"right",
        marginBottom:"15px",
        border:"2px solid rgb(100, 46, 171)",
        borderRadius:"5px",
        padding:"5px"
      }}
        type={"text"}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={"Search"}
      />
      <div className="">
        {submissions.length ? (
          <MDBTable hover striped bordered>
            <MDBTableHead color="primary" textWhite>
              <tr className="bg-warning">
                <th>Team ID</th>
                <th>Challenge</th>
                <th>Track</th>
                <th>Team Name</th>
                <th>Total Members</th>
                <th>Total Mentors</th>
                <th>Created at</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {submissions
                .filter((submission) => {
                  if (search === "") {
                    return true;
                  }

                  return (
                    submission.name
                      .toLowerCase()
                      .search(search.toLowerCase()) !== -1 ||
                    submission.challenge
                      .toLowerCase()
                      .search(search.toLowerCase()) !== -1
                  );
                })
                .map((submission, index) => (
                  <tr key={index}>
                    <td>{submission.id}</td>
                    <td>
                      <span>
                        {submission.challenge.toUpperCase()} Challenge
                      </span>
                    </td>
                    <td>{submission.track_name}</td>
                    <td>{submission.name}</td>
                    <td>{submission.members_count}</td>
                    <td>{submission.mentors_count}</td>
                    <td>{submission.created_at}</td>
                    <td>
                      <MDBBtn
                        color={"warning"}
                        className="col p-2"
                        onClick={() => {
                          setChallenge(submission.challenge_id, true, false);
                          store("stage", 1);
                          RouteHelper.redirect("/team/my-team/members");
                        }}
                      >
                        View Members
                      </MDBBtn>
                      <br />
                      <MDBBtn
                        color={"warning"}
                        className="col p-2 mt-3"
                        onClick={() => {
                          setChallenge(submission.challenge_id, true, false);
                          store("stage", 1);
                          RouteHelper.redirect("/team/my-team/mentors");
                        }}
                      >
                        View Mentors
                      </MDBBtn>
                    </td>
                  </tr>
                ))}
            </MDBTableBody>
          </MDBTable>
        ) : (
          <h4 className={"text-center text-warning mb-4"}>
            No teams formed yet!
          </h4>
        )}
      </div>
    </div>
  );
};
