
import React from "react";
import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import auth from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteHelper from "../../utils/routeHelper";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import axios from "../../utils/axios";

export default function ProblemStatementsPage(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [problems, setProblems] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [currentPs, setCurrentPs] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [org, setOrg] = useState(false)
  const [orgvalue,setOrgvalue] = useState(0)
  
  
  const [val,setVal] = useState({
    category : '',
   title : '',
   description : '',
   guidelines_file :[],
   datasheet_file : [],
   organization_id : '',
   video_url : '',
   theme : ''
    
   })

  useEffect(() => {
    axios()
      .get("/Organizations")
      .then((response) => {
        setOrg(response.data.data);
        console.log(response.data.data);
        setIsLoading(false);
      });

      axios()
      .get("/problems")
      .then((response) => {
        setProblems(response.data.data);
        console.log(response.data.data);
        setIsLoading(false);
      });

    

      axios()
      .get("/tracks")
      .then((response) => {
        setTracks(response.data.data);
        console.log(response.data.data);
        console.log("hello");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  function handleSubmit(e){
    e.preventDefault();

    const data = new FormData(e.target);
    data.append("category",val.category);
    data.append("title",val.title);
    data.append("description",val.description);
    data.append("guidelines_file",val.guidelines_file);
    data.append("datasheet_file",val.datasheet_file);
    data.append("video_url",val.video_url);
    data.append("theme",val.theme);
    data.append("organization_id",orgvalue)
    console.log(data);
    toast.promise(
      axios().post(`/problems`, data),
      {
        pending: {
          render() {
            setIsSubmitting(true);
            return "Submitting....";
          },
        },
        success: {
          render() {

                  axios()
            .get("/problems")
            .then((response) => {
              props.setProblems(response.data.data);
              console.log(response.data.data);
              setIsLoading(false);
            });
      
            setIsSubmitting(false);
            return "Submitted successfully!";
          },
        },
        error: {
          render({ data }) {
            setIsSubmitting(false);
            let status = data.response.status;
            data = data.response.data;
            if (status === 422) {
              Object.entries(data.errors).forEach(([key, value]) => {
                toast.error(value.toString(), {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });

              return "There were errors in some fields";
            } else {
              return "Something went wrong!";
            }
          },
        },
       
      }
    );
    console.log(data)
    
    
  }
 
  return (
    <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
      <MDBContainer className="mt-4 px-2 mb-4">
        <MDBRow className="row d-flex justify-content-center">
          <MDBCol md="6">
            <MDBCard className="my-4">
              <MDBCardBody className="shadow">
                <h3 className="text-center"> Upload your problem statements here </h3>
                <form onSubmit = {handleSubmit}>
                  <div>
                    <div className="text-dark">
                      <h6 className="mb-2 pb-1 fw-bold">Category </h6>
                      <div>
                      <select
                        required={true}
                        className="form-control form-select"
                        name= "category"
                        label="Category"
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      >
                         <option value="">Choose the category</option>
                        <option value="sw">Software</option>
                        <option value="hw">Hardware</option>
                        
                      </select>
                    </div>
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Title </h6>
                      <MDBInput
                        required={true}
                        label="Title"
                        
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        name="title"
                        onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })} 
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Description </h6>
                      <MDBInput type="textarea" name="description" label="Description" rows="5"  
                      onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                      required = {true}
                      />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Upload Guidelines file </h6>
                      <MDBInput
                  group
                  type="file"
                  validate
                  error="wrong"
                  success="right"
                  name={"guidelines_file"}
                  accept={"application/pdf"}
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.files[0] })}
                 
                />
                      <br></br>
                      <h6 className="mb-2 pb-1 fw-bold">Upload Datasheets file </h6>
                      <MDBInput
                  group
                  type="file"
                  validate
                  error="wrong"
                  success="right"
                  name={"datasheet_file"}
                  accept={"application/pdf"}
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.files[0] })}
                
                />


                    <br></br>

                    <h6 className="mb-2 pb-1 fw-bold">Upload Video URL </h6>
                      <MDBInput
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  name="video_url"
                  
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                
                />


                    <br></br>

                    <h6 className="mb-2 pb-1 fw-bold">Theme </h6>
                      <MDBInput
                  group
                  type="text"
                  validate
                  error="wrong"
                  success="right"
                  name="theme"
                 
                  onChange = {(e) => setVal({...val, [e.target.name] : e.target.value })}
                
                />


                    <br></br>
                    <h6 className="mb-2 pb-1 fw-bold">Organization </h6>

                    <select
                        required={true}
                        className="form-control form-select mt-4 mb-4"
                        name= "Organization"
                        label="Organization"              
                        onChange = {(e) => setOrgvalue(e.target.value)} 
                      >
                         <option value="">Choose Organization</option>
                        {Array.from(new Set(org.map((or) => <option value={or.id}>{or.name}</option>
        )))}
                        
                      </select>



                    {
                    Object.entries(tracks).map(([category_name, tracks]) => <div className="d-flex flex-column mt-4" key={category_name}>
                      {tracks.map((track, id) =>  < >
                   <div  style = {{display : "flex" , flexDirection : "row"}}>
                      <p className = "mr-3"> {track.name} </p>
                      <input class="form-check-input" value = {track.id}   name = "tracks[]" type="checkbox" id="checkboxNoLabel" varia-label="..." />
                      
                  </div>
                  
                    
                   </>)  }
                   </div>
                   )
                   }

                   </div>
                  <div className="text-center py-3">
                    <MDBBtn
                      className="shadow"
                      color="danger"
                      
                      type="submit"
                      
                    >
                     Submit
                    </MDBBtn>
                  </div>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

